import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Box, Divider } from '@mui/material';

import config from '../../config';
import { primaryColor } from '../../model/arrays';
import { copyToClipboard } from '../../utils/copyToClipboard';
import Prize from './Prize';

const DonationInfo = () => {
  return (
    <Box
      sx={{
        border: 2,
        borderColor: primaryColor,
        backgroundColor: '#e3e3e3',
        borderRadius: 3,
        width: 'fit-content',
        maxWidth: '85%',
        padding: 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: 1,
      }}
    >
      <h3 style={{ marginTop: 0, marginBottom: 0 }}>Donation Info</h3>
      <p>
        Please make a donation to{' '}
        <span
          style={{
            fontFamily: 'monospace',
            background: 'gray',
            padding: 4,
            borderRadius: 2,
            color: 'white',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
          }}
          onClick={() => copyToClipboard(config.DONATION_CONTACT)}
        >
          {config.DONATION_CONTACT} <ContentPasteIcon fontSize="inherit" />
        </span>{' '}
        on <span style={{ fontWeight: 'bold' }}>{config.DONATION_METHOD}</span>
      </p>
      <Box
        component="img"
        src="/donation.png"
        alt="Donation QR code"
        sx={{ width: 300, maxWidth: '100%', borderRadius: 5, boxShadow: 3 }}
      />
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Prize />
    </Box>
  );
};

export default DonationInfo;
