import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import BoardView from '../components/BoardView';
import Bracket from '../components/Bracket';
import ShowInstructions from '../components/FloatingButtons/ShowInstructions';
import StartOver from '../components/FloatingButtons/StartOver';
import config from '../config';
import {
  athletesState,
  boardState,
  bracketsState,
  consolationBracketsState,
  contactState,
  modeState,
  selectionState,
  settingsState,
} from '../model/store';
import {
  fetchAllSettings,
  fetchAthlete,
  fetchBrackets,
  fetchConsolationBracket,
  fetchSubmit,
} from '../utils/fetch';

const View = () => {
  const setMode = useSetRecoilState(modeState);
  const setSettings = useSetRecoilState(settingsState);
  const [brackets, setBrackets] = useRecoilState(bracketsState);
  const setConsolationBrackets = useSetRecoilState(consolationBracketsState);
  const [athletes, setAthletes] = useRecoilState(athletesState);
  const [selectedId, setSelectedId] = useState('');
  const setSelectedList = useSetRecoilState(selectionState);
  const boards = useRecoilValue(boardState);
  const setContact = useSetRecoilState(contactState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setMode('view');
    const path = window.location.pathname;
    const pathSplit = path.split('/');
    if (pathSplit.length === 3) {
      setSelectedId(pathSplit[2]);
    }
  }, []);

  useEffect(() => {
    if (boards && boards.length > 0) {
      const board = boards.find((b) => b.id === selectedId);
      if (board) {
        setContact({
          first: board.firstname,
          last: board.lastname,
        });
      }
    }
  }, [boards]);

  useEffect(() => {
    if (!selectedId) {
      return;
    }
    fetchSubmit(selectedId).then(
      (items) => {
        // setIsLoaded(true);
        setSelectedList(items.selection);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, [selectedId]);

  document.title = config.TITLE;
  useEffect(() => {
    fetchAllSettings().then(
      // setIsLoaded(true);
      (result) => {
        setSettings(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchBrackets().then(
      (result) => {
        // setIsLoaded(true);
        setBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchConsolationBracket().then(
      (result) => {
        // setIsLoaded(true);
        setConsolationBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchAthlete().then(
      (items) => {
        // setIsLoaded(true);
        setAthletes(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  return (
    <>
      <StartOver />
      <ShowInstructions />
      {brackets !== undefined && athletes !== undefined ? <Bracket /> : <></>}
      <BoardView />
    </>
  );
};

export default View;
