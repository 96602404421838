import { Button } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Subtitle from '../components/Subtitle';
import TieBreakers from '../components/TieBreakers';
import Title from '../components/Title';
import WhoTheyGotBoardView from '../components/WhoTheyGotBoardView';
import config from '../config';
import {
  athletesState,
  bracketsState,
  consolationBracketsState,
  settingsState,
} from '../model/store';
import {
  fetchAllSettings,
  fetchAthlete,
  fetchBrackets,
  fetchConsolationBracket,
} from '../utils/fetch';

const WhoTheyGot = () => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const setBrackets = useSetRecoilState(bracketsState);
  const setConsolationBrackets = useSetRecoilState(consolationBracketsState);
  const setAthletes = useSetRecoilState(athletesState);

  document.title = config.TITLE;
  useEffect(() => {
    fetchAllSettings().then(
      // setIsLoaded(true);
      (result) => {
        setSettings(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchBrackets().then(
      (result) => {
        // setIsLoaded(true);
        setBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchConsolationBracket().then(
      (result) => {
        // setIsLoaded(true);
        setConsolationBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchAthlete().then(
      (items) => {
        // setIsLoaded(true);
        setAthletes(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  // Undecided
  if (settings.allowEntry === true || settings.allowView === 0) {
    return (
      <>
        <p>Please wait until the tournament starts to use this</p>
        <Link to="/">
          <Button>Home</Button>
        </Link>
      </>
    );
  }
  return (
    <>
      <Title />
      <Subtitle />
      <WhoTheyGotBoardView />
      <TieBreakers />
    </>
  );
};

export default WhoTheyGot;
