import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RouteIcon from '@mui/icons-material/Route';
import { Box, Grid } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import type { SetterOrUpdater } from 'recoil';
import { useSetRecoilState } from 'recoil';

import type { CreationMode } from '../model/models';
import { creationModeState } from '../model/store';

function handleClick(
  setter: SetterOrUpdater<CreationMode>,
  mode: CreationMode,
  modeDisplayName: string
) {
  setter(mode);
  toast.success(`Selected ${modeDisplayName}`);
}

const Card = (props: {
  title: string;
  subtitle: string;
  mode: CreationMode;
  children: React.ReactNode;
}) => {
  const setCreationMode = useSetRecoilState(creationModeState);

  return (
    <Grid item xs={12} sm={6}>
      <Box
        sx={{
          borderRadius: '20px',
          backgroundColor: '#f5f5f5',
          height: '300px',
          margin: 2,
          padding: 2,
          '&:hover': {
            boxShadow: 3,
          },
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onClick={() => handleClick(setCreationMode, props.mode, props.title)}
      >
        <h3 style={{ fontSize: '24px', marginTop: 2, marginBottom: 12 }}>
          {props.title}
        </h3>
        <div
          style={{
            maxWidth: '45%',
            maxHeight: '45%',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#d7d7d7',
            borderRadius: '15px',
            padding: 2,
          }}
        >
          {props.children}
        </div>
        <p style={{ height: '40px' }}>{props.subtitle}</p>
      </Box>
    </Grid>
  );
};

const CreationModeSelect = () => {
  return (
    <>
      <p>
        Select the how you want to fill out your bracket. These are just two
        different ways to get to the same result.
      </p>
      <Grid
        container
        sx={{
          maxWidth: 800,
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          alignItems: 'stretch', // set alignItems to stretch
        }}
        direction="row"
      >
        <Card title="Bracket Mode" subtitle="Virtual bracket" mode="bracket">
          <RouteIcon sx={{ height: '100%', width: '100%' }} />
        </Card>
        <Card
          title="List Mode"
          subtitle="For advanced users who know who they want to pick."
          mode="list"
        >
          <FormatListNumberedIcon sx={{ height: '100%', width: '100%' }} />
        </Card>
      </Grid>
    </>
  );
};

export default CreationModeSelect;
