const TieBreakers = () => {
  return (
    <p style={{ color: 'gray' }}>
      <i>
        tie breakers in order: highest average seed, highest wildcard seed, most
        pins, most bonus points, most champions, random
      </i>
    </p>
  );
};

export default TieBreakers;
