import CasinoIcon from '@mui/icons-material/Casino';
import { Box, Button } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import { primaryColor } from '../../model/arrays';
import {
  athletesState,
  bracketsState,
  modeState,
  showInstructionsState,
} from '../../model/store';
import AthleteCard from '../AthleteCard';

const InstructionsBracketView = () => {
  const weight = 125;
  const mode = useRecoilValue(modeState);
  const br = useRecoilValue(bracketsState)[weight];
  const athletes = useRecoilValue(athletesState);
  const [show, setShow] = useRecoilState(showInstructionsState);

  const bout = br?.round1.find((b) => b.boutA < 8);

  if (!show || !athletes || !athletes[0] || !athletes[0].seed || !bout) {
    return <></>;
  }

  const athletesInWeight = athletes.filter(
    (athlete) => athlete.weight === weight
  );

  const boutWinning = br?.round1.find((b) => b.winner === b.boutA);
  const boutLoser = br?.round1.find((b) => b.winner !== b.boutB);

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        borderRadius: 3,
        marginBottom: 3,
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '800px',
        maxWidth: '95%',
        border: '3px solid',
        borderColor: 'black',
        overflowX: 'auto',
        position: 'relative',
        boxShadow: 3,
      }}
    >
      <div style={{ margin: 12 }}>
        <h3 style={{ marginBottom: 0, marginTop: 0 }}>Instructions</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p>Your selected athletes are marked like this</p>
          </div>
          <div style={{ width: 400, marginLeft: 25, marginRight: 25 }}>
            <AthleteCard
              isA={true}
              bout={bout}
              selected={bout.boutA}
              mode={mode}
              athletesInWeight={athletesInWeight}
              deactivateClick={true}
              hideWildcard={true}
            />
          </div>
          {boutWinning ? (
            <>
              <div>
                <p>The winner of a match is marked with a thick outline</p>
              </div>
              <div style={{ width: 400 }}>
                <AthleteCard
                  isA={true}
                  bout={boutWinning}
                  selected={0}
                  mode={mode}
                  athletesInWeight={athletesInWeight}
                  deactivateClick={true}
                  hideWildcard={true}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {boutLoser ? (
            <>
              <div>
                <p>Losers are struck through</p>
              </div>
              <div style={{ width: 400 }}>
                <AthleteCard
                  isA={false}
                  bout={boutLoser}
                  selected={0}
                  mode={mode}
                  athletesInWeight={athletesInWeight}
                  deactivateClick={true}
                  hideWildcard={true}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            <p>
              The selected wildcard is the only one that has this symbol:
              <CasinoIcon
                sx={{
                  color: primaryColor,
                  cursor: 'pointer',
                  fontSize: 'inherit',
                }}
              />
            </p>
          </div>
          <Button
            size={'large'}
            variant={'outlined'}
            onClick={() => setShow(false)}
          >
            close
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default InstructionsBracketView;
