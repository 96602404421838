import CasinoIcon from '@mui/icons-material/Casino';
import { Box, Button } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import config from '../../config';
import { primaryColor } from '../../model/arrays';
import {
  athletesState,
  bracketsState,
  showInstructionsState,
} from '../../model/store';

const InstructionsListCreate = () => {
  const weight = 125;
  const br = useRecoilValue(bracketsState)[weight];
  const athletes = useRecoilValue(athletesState);
  const [show, setShow] = useRecoilState(showInstructionsState);

  const bout = br?.round1.find((b) => b.boutA < 8);

  if (!show || !athletes || !athletes[0] || !athletes[0].seed || !bout) {
    return <></>;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        borderRadius: 3,
        marginBottom: 3,
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '800px',
        maxWidth: '95%',
        border: '3px solid',
        borderColor: 'black',
        overflowX: 'auto',
        position: 'relative',
        boxShadow: 3,
      }}
    >
      <div style={{ margin: 12 }}>
        <h3 style={{ marginBottom: 0, marginTop: 0 }}>Instructions</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p>
              1. Below, select one athlete for each of the 10 weight classes.
              The selected athlete is indicated by a black box around the seed
              number.
            </p>
          </div>
          <div>
            <p>
              2. You can find constraints below (gray box), these turn red when
              exceeded. These are not requirements, only contraints.
              <br />
              Example: You don't need to pick 3 top 3 seeds, you just can't pick
              4 or more.
            </p>
          </div>
          <div>
            <p>
              3. <span style={{ fontWeight: 'bold' }}>Wildcard</span>: Select
              one wildcard, by clicking the symbol{' '}
              <CasinoIcon
                style={{
                  color: primaryColor,
                  cursor: 'pointer',
                  fontSize: 'inherit',
                }}
              />
              . This can be any seed greater than {config.WILDCARD_MIN_SEED - 1}
              .
            </p>
          </div>
          <div>
            <p>
              4. After you submitted your bracket, please make your donation to
              get your bracket verified.
            </p>
          </div>
          <Button
            size={'large'}
            variant={'outlined'}
            onClick={() => setShow(false)}
          >
            close
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default InstructionsListCreate;
