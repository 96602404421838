import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { Bracket } from '../model/models';
import {
  bracketShowConsolationState,
  bracketsState,
  bracketState,
  consolationBracketsState,
  consolationBracketState,
  contactState,
  creationModeState,
  modeState,
  selectedWeightState,
} from '../model/store';
import BracketSwitch from './BracketSwitch';
import BracketView from './BracketView';
import ConsolationView from './ConsolationView';
import Constraints from './Constraints/Constraints';
import FloatWrapper from './Constraints/FloatWrapper';
import { WeightCookies } from './Cookies';
import CreationModeSelect from './CreationModeSelect';
import InstructionsBracketCreate from './Instructions/InstructionsBracketCreate';
import InstructionsBracketView from './Instructions/InstructionsBracketView';
import InstructionsListCreate from './Instructions/InstructionsListCreate';
import List from './List/List';
import Submit from './Submit/Submit';
import WeightButtonsView from './WeightButtons';

const BracketComponent = () => {
  const setBracket = useSetRecoilState(bracketState);
  const [consolationBracket, setConsolationBracket] = useRecoilState(
    consolationBracketState
  );
  const weightclass = useRecoilValue(selectedWeightState);
  const mode = useRecoilValue(modeState);
  const bracketShowConsolation = useRecoilValue(bracketShowConsolationState);
  const brackets = useRecoilValue(bracketsState);
  const consolationBrackets = useRecoilValue(consolationBracketsState);
  const creationMode = useRecoilValue(creationModeState);

  useEffect(() => {
    if (brackets !== null) {
      setBracket(brackets[weightclass] as unknown as Bracket);
    }
  }, [weightclass]);

  useEffect(() => {
    if (consolationBracket !== null) {
      setConsolationBracket(
        consolationBrackets[weightclass] as unknown as Bracket
      );
    }
  }, [weightclass]);

  const BracketTitle = () => {
    const name = useRecoilValue(contactState);

    return (
      <div className="bracket-title">
        <h2>{name.first}'s bracket</h2>
      </div>
    );
  };

  if (mode === 'create') {
    if (!creationMode) {
      return (
        <div className="bracket">
          <BracketTitle />
          <CreationModeSelect />
        </div>
      );
    }
    if (creationMode === 'bracket') {
      return (
        <div className="bracket">
          <BracketTitle />
          <WeightCookies />
          <InstructionsBracketCreate />
          <WeightButtonsView showLabel={true} />
          <Submit />
          <BracketView>
            <FloatWrapper>
              <Constraints />
            </FloatWrapper>
          </BracketView>
        </div>
      );
    }
    if (creationMode === 'list') {
      return (
        <div className="bracket">
          <BracketTitle />
          <WeightCookies />
          <InstructionsListCreate />
          <List />
        </div>
      );
    }
  }
  return (
    <div className="bracket">
      <BracketTitle />
      <InstructionsBracketView />
      <WeightButtonsView />
      <BracketSwitch />
      {!bracketShowConsolation ? (
        <BracketView>
          <></>
        </BracketView>
      ) : (
        <ConsolationView />
      )}
    </div>
  );
};

export default BracketComponent;
