import React from 'react';

import config from '../config';

const Title = () => {
  const titleStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '800px',
    fontSize: 'calc(1.5rem + 3vw)',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: 0,
  };

  return <h1 style={titleStyle}>{config.TITLE}</h1>;
};

export default Title;
