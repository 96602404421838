import type { Weight } from '../models';

const weightclasses = [
  125 as Weight,
  133 as Weight,
  141 as Weight,
  149 as Weight,
  157 as Weight,
  165 as Weight,
  174 as Weight,
  184 as Weight,
  197 as Weight,
  285 as Weight,
];

export default weightclasses;
