import CheckIcon from '@mui/icons-material/Check';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import type { SxProps } from '@mui/material';
import { Box, Grid, useTheme } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import config from '../../config';
import type { Bracket, Weight } from '../../model/models';
import {
  athletesState,
  bracketsState,
  constraintsState,
  selectionState,
} from '../../model/store';
import Constraints from '../Constraints/Constraints';
import Submit from '../Submit/Submit';
import WeightButtonsView from '../WeightButtons';
import AthleteCard from './AthleteCard';

const Column = (props: { title: string; bracket: Bracket }) => {
  const theme = useTheme();
  const athletes = useRecoilValue(athletesState);
  const selectedList = useRecoilValue(selectionState);

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const collapseStyle: SxProps = {
    backgroundColor: 'lightgray',
    borderRadius: '100%',
    padding: 0.5,
    marginRight: 1,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginRight: collapsed ? 0 : 1,
      marginBottom: collapsed ? 1 : 0,
    },
  };

  return (
    <Grid
      item
      xs={false}
      md={0}
      sx={{
        width: '325px',
        maxWidth: '325px',
        border: 1,
        borderRadius: 3,
        margin: 1,
        flexShrink: 0,
        transition: '0.3s ease-in-out',
        [theme.breakpoints.up('md')]: {
          maxWidth: !collapsed ? '325px' : '50px',
          width: !collapsed ? '325px' : '50px',
        },
      }}
    >
      <Box style={{ overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
              flexDirection: collapsed ? 'column' : 'row',
            },
          }}
        >
          <h2 style={{ flexGrow: 1 }}>{props.title} lbs</h2>
          {selectedList.weights[parseInt(props.title, 10) as Weight] !== 0 ? (
            <CheckIcon color={'success'} sx={{ margin: 1 }} />
          ) : (
            <></>
          )}
          {collapsed ? (
            <UnfoldMoreIcon
              onClick={() => setCollapsed(!collapsed)}
              sx={collapseStyle}
            />
          ) : (
            <UnfoldLessIcon
              onClick={() => setCollapsed(!collapsed)}
              sx={collapseStyle}
            />
          )}
        </Box>
        {!collapsed ? (
          <Grid container direction={'column'} width={'100%'}>
            {athletes
              .filter(
                (athlete) =>
                  athlete.weight === parseInt(props.title, 10) &&
                  athlete.seed <= config.BRACKET_SIZE
              )
              .map((athlete) => (
                <Grid item>
                  <AthleteCard athlete={athlete} />
                </Grid>
              ))}
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
};

const List = () => {
  const theme = useTheme();
  const br = useRecoilValue(bracketsState);
  const constraints = useRecoilValue(constraintsState);

  return (
    <>
      <WeightButtonsView preventClick={true} />
      <Box
        display={'flex'}
        justifyContent={'center'}
        marginTop={2}
        marginBottom={2}
      >
        <Constraints />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Submit />
      </Box>

      <Box
        sx={{
          position: 'fixed',
          left: 0,
          bottom: -1,
          backgroundColor: '#ededed',
          width: '100vw',
          height: 'content',
          zIndex: 200,
          justifyContent: 'center',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: { display: 'none' },
        }}
      >
        {!constraints ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Constraints />
          </Box>
        ) : (
          <></>
        )}
        <Submit noSucess={true} />
      </Box>

      <Grid
        container
        sx={{
          justifyContent: 'center',
          marginBottom: '5rem',
          [theme.breakpoints.up('md')]: {
            justifyContent: 'start',
            overflow: 'auto',
            flexWrap: 'nowrap',
            marginBottom: 0,
          },
        }}
      >
        {Object.entries(br).map(([key, weightClass]) => {
          if (weightClass) {
            return <Column title={key} bracket={weightClass} />;
          }
          return <></>;
        })}
      </Grid>
    </>
  );
};

export default List;
