// 3 in Top 3
// 2 from a Team
// 4 From a Conference
// 7 From Big 10 or Big 12
// wild card > 8 seed (team stipulation ignored)

import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import config from '../../config';
import { weightclasses } from '../../model/arrays';
import type {
  Athlete,
  Constraints as ConstraintsType,
  Selection,
} from '../../model/models';
import {
  athletesState,
  constraintsState,
  selectionState,
} from '../../model/store';
import Row from './Row';

function getConstraintInTop3(selectedList: Selection) {
  return Object.values(selectedList.weights).filter(
    (seed) => seed > 0 && seed <= 3
  ).length;
}

function getPickedAthletes(athletes: Athlete[], selectedList: Selection) {
  if (athletes.length > 0) {
    const pickedAthletes = weightclasses.map(
      (weightclass) =>
        athletes.filter(
          (athlete) =>
            athlete.weight === weightclass &&
            athlete.seed === selectedList.weights[weightclass]
        )[0]
    );
    return pickedAthletes;
  }
  return [];
}

function getConstraints(
  pickedAthletes: Athlete[] | undefined,
  inTop3: number,
  selectedList: Selection
) {
  if (
    pickedAthletes === undefined ||
    pickedAthletes.filter((c) => c === undefined).length === 10
  ) {
    return {
      conference: {
        count: 0,
        name: '?',
        bool: true,
      },
      team: {
        count: 0,
        name: '?',
        bool: true,
      },
      big: {
        count: 0,
        bool: true,
      },
      seed: {
        count: getConstraintInTop3(selectedList),
        bool: true,
      },
    } as ConstraintsType;
  }

  const conferences = pickedAthletes
    .filter((athlete) => athlete !== undefined)
    .map((athlete) => athlete.conferenceName);
  const teams = pickedAthletes
    .filter((athlete) => athlete !== undefined)
    .map((athlete) => athlete.schoolName);

  const c = conferences.map(
    (conference) => conferences.filter((conf) => conf === conference).length
  );
  const cmax = Math.max(...c);

  const teamsList = teams.map((team) => teams.filter((t) => t === team).length);
  const tmax = Math.max(...teamsList);

  const big = conferences.filter(
    (conference) => conference === 'BIG10' || conference === 'BIG12'
  ).length;

  return {
    conference: {
      count: cmax,
      name: conferences[c.indexOf(cmax)],
      bool: config.BRACKET_SIZE === 16 || cmax < 5,
    },
    team: {
      count: tmax,
      name: teams[teamsList.indexOf(tmax)],
      bool: config.BRACKET_SIZE === 16 ? tmax <= 4 : tmax <= 2,
    },
    big: {
      count: big,
      bool: config.BRACKET_SIZE === 16 || big < 8,
    },
    seed: {
      count: inTop3,
      bool: inTop3 < 4,
    },
  } as ConstraintsType;
}

const Constraints = () => {
  const selectedList = useRecoilValue(selectionState);
  const athletes = useRecoilValue(athletesState);
  const [constraintsMet, setConstraintsMet] = useRecoilState(constraintsState);

  const pickedAthletes = getPickedAthletes(athletes, selectedList);
  const inTop3 = getConstraintInTop3(selectedList);
  const constraints = getConstraints(pickedAthletes, inTop3, selectedList);

  useEffect(() => {
    setConstraintsMet(
      constraints.seed.bool &&
        constraints.team.bool &&
        constraints.conference.bool &&
        constraints.big.bool
    );
  }, []);

  useEffect(() => {
    setConstraintsMet(
      constraints.seed.bool &&
        constraints.team.bool &&
        constraints.conference.bool &&
        constraints.big.bool
    );
  }, [selectedList]);

  return (
    <Box
      sx={{
        width: 275,
        backgroundColor: 'lightgray',
        border: constraintsMet ? 1 : 3,
        borderColor: constraintsMet ? 'black' : 'red',
        borderRadius: '0.75rem',
        padding: 1,
        boxShadow: 5,
      }}
    >
      <Row
        title={'top 3 seeds'}
        numerator={constraints.seed.count}
        denominator={3}
      />
      <Row
        title={'same team'}
        hint={!constraints.team.bool ? constraints.team.name : ''}
        numerator={constraints.team.count}
        denominator={config.BRACKET_SIZE === 16 ? 4 : 2}
      />
      {config.BRACKET_SIZE === 33 ? (
        <>
          <Row
            title={'same conference'}
            hint={
              !constraints.conference.bool ? constraints.conference.name : ''
            }
            numerator={constraints.conference.count}
            denominator={4}
          />
          <Row
            title={'BIG 10 / BIG 12'}
            numerator={constraints.big.count}
            denominator={7}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Constraints;
