import styled from '@emotion/styled';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { contactState, selectionState, submitState } from '../../model/store';
import clearLocalStorage from '../../utils/clearLocalStorage';
import { postSubmit } from '../../utils/fetch';

interface JumpButtonProps extends ButtonProps {
  isJumping: boolean;
}

const SubmitReady = () => {
  const selectedList = useRecoilValue(selectionState);
  const contact = useRecoilValue(contactState);
  const [errorMessage, setErrorMessage] = useState<string>();
  const setSubmission = useSetRecoilState(submitState);
  const [jumping, setJumping] = useState(true);

  const handleMouseEnter = () => {
    setJumping(false);
  };

  const handleMouseLeave = () => {
    setJumping(true);
  };

  function fetch() {
    // Call the postSubmit function to post the data and get the response
    const res = postSubmit(selectedList, contact);
    res.then(
      (result) => {
        // Check if the result is an error object
        if (result instanceof Error) {
          // Check if the error message includes the HTTP status code 409
          if (result.message.includes('400')) {
            // Handle error for HTTP status code 400
            setErrorMessage('Invalid wild card');
          }
          // Check if the error message includes the HTTP status code 406
          else if (result.message.includes('406')) {
            // Handle error for HTTP status code 406
            setErrorMessage(
              'The bracket you submitted contains invalid elements. Please try again.'
            );
          }
          // Check if the error message includes the HTTP status code 406
          else if (result.message.includes('409')) {
            // Handle error for HTTP status code 406
            setErrorMessage(
              'Looks like someone submitted the same bracket before you did. Please make some changes.'
            );
          }
          // Handle other error cases
          else {
            setErrorMessage('an unknown error occurred');
          }
          return false;
        }
        // setIsLoaded(true);
        toast.success('Submitted!');

        // Set the submission data
        setSubmission(result);

        // Clear the local storage
        clearLocalStorage();

        // If the response includes a secret, store it in local storage
        if (result.secret) {
          if (localStorage.getItem('secret')) {
            const oldString = localStorage.getItem('secret');
            localStorage.setItem(
              'secret',
              `${oldString};${localStorage.getItem('secret')}`
            );
          }
          localStorage.setItem('secret', result.secret);
        }

        // Return true if everything is successful
        return true;
      },
      // Handle errors
      (_error) => {
        // setIsLoaded(true);
        return false;
        // setError(error);
      }
    );
  }

  const JumpButton = styled(Button)<JumpButtonProps>((props) => ({
    animation: props.isJumping ? 'jump 0.8s ease-in-out' : '',
    animationIterationCount: props.isJumping ? 'infinite' : '',
    '&:hover': {
      animation: 'none',
    },
    '@keyframes jump': {
      '0%': {
        transform: 'translateY(0)',
      },
      '50%': {
        transform: 'translateY(-5px)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
  }));

  return (
    <>
      <JumpButton
        onClick={() => fetch()}
        variant="contained"
        color="primary"
        // size="large"
        isJumping={jumping}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{ paddingLeft: 6, paddingRight: 6, margin: 1, boxShadow: 2 }}
      >
        Submit!
      </JumpButton>
      {errorMessage ? (
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <p
            style={{
              backgroundColor: '#242424',
              color: 'white',
              padding: 10,
              maxWidth: '800px',
              borderRadius: 5,
              margin: 0,
            }}
          >
            {errorMessage}
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubmitReady;
