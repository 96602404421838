/* eslint-disable no-nested-ternary */
import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useRecoilValue } from 'recoil';

import config from '../config';
import type { Bout, Round, Weight } from '../model/models';
import {
  athletesState,
  bracketsState,
  modeState,
  selectedWeightState,
  selectionState,
} from '../model/store';
import AthleteCard from './AthleteCard';

const Bracket = (props: { round: Round }) => {
  const mode = useRecoilValue(modeState);
  const selectedList = useRecoilValue(selectionState);
  const selectedWeight = useRecoilValue(selectedWeightState);
  const athletes = useRecoilValue(athletesState);
  const selected = selectedList.weights[selectedWeight as unknown as Weight];
  const roundwidth = 500;

  const athletesInWeight = athletes.filter(
    (athlete) => athlete.weight === selectedWeight
  );

  if (Array.isArray(props.round)) {
    const inner = props.round.map((bout: Bout) => (
      <Grid
        container
        key={bout.bout.toString()}
        direction="column"
        className="bout"
        sx={{
          marginTop: 4,
          marginBottom: 4,
          marginLeft: 2,
          marginRight: 6,
          padding: 0,
        }}
      >
        <AthleteCard
          isA={true}
          bout={bout}
          selected={selected}
          mode={mode}
          athletesInWeight={athletesInWeight}
        />
        <Grid
          container
          justifyContent="space-between"
          sx={{ paddingLeft: 7.25, paddingRight: 2.25 }}
        >
          <Grid sx={{ marginTop: 0.5, marginBottom: 0.5 }}>
            <i>
              {props.round.length !== 1
                ? `bout ${bout.bout}`
                : `Championship (${bout.bout})`}
            </i>
          </Grid>
          {bout.winCondition !== undefined && bout.winCondition !== null ? (
            <Grid sx={{ marginTop: 0.5, marginBottom: 0.5, marginRight: 3 }}>
              {`${bout.winCondition.name} (${bout.winCondition.points})`}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <AthleteCard
          isA={false}
          bout={bout}
          selected={selected}
          mode={mode}
          athletesInWeight={athletesInWeight}
        />
      </Grid>
    ));
    return (
      <Grid container maxWidth={roundwidth} alignContent="space-around">
        {inner}
      </Grid>
    );
  }
  return <></>;
};

const BracketView = (props: { children: React.ReactNode }) => {
  const selectedWeight = useRecoilValue(selectedWeightState);
  const br = useRecoilValue(bracketsState)[selectedWeight];
  const athletes = useRecoilValue(athletesState);
  const roundwidth = 500;

  if (br === undefined || br === null || athletes.length === undefined) {
    return <CircularProgress />;
  }

  return (
    <div className="bracket">
      <Box
        sx={{ flexGrow: 1, backgroundColor: '#f9f9f9', overflowY: 'auto' }}
        width={'100%'}
      >
        <Typography
          variant={'h3'}
          fontSize={18}
          fontFamily={'inherit'}
          sx={{
            width: '200px',
            marginTop: 2,
            position: 'absolute',
            left: '50%',
            right: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {`${selectedWeight} lbs weight class`}
        </Typography>
        <Grid
          container
          direction="row"
          flexWrap="nowrap"
          width={roundwidth * (config.BRACKET_SIZE === 33 ? 5 : 4)}
        >
          {config.BRACKET_SIZE === 33 ? <Bracket round={br.round1} /> : <></>}
          <Bracket round={br.round2} />
          <Bracket round={br.round3} />
          <Bracket round={br.round4} />
          <Bracket round={br.round5} />
        </Grid>
        {props.children}
      </Box>
    </div>
  );
};

export default BracketView;
