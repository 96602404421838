import CheckIcon from '@mui/icons-material/Check';
import TocIcon from '@mui/icons-material/Toc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { Board, Board as BoardType, Settings } from '../model/models';
import { boardState, settingsState } from '../model/store';
// import Submission from "../controller/GetSubmit";
import { epochToLocale } from '../utils/epochToHuman';
import { checkAuth, fetchBoard, fetchWagerAllowed } from '../utils/fetch';
import Details from './Details';

const Row = (props: {
  board: Board;
  index: number;
  allowView: boolean;
  allowed?: string[];
  setShowDetails: any;
}) => {
  return (
    <TableRow key={props.board.id}>
      <TableCell>{props.index + 1}</TableCell>
      <TableCell>
        {props.board.firstname} {props.board.lastname}
      </TableCell>
      <TableCell>{props.board.score}</TableCell>
      <TableCell>
        {props.allowView ? (
          <Button
            sx={{ size: 'small', color: 'primary' }}
            onClick={() => props.setShowDetails(props.board)}
          >
            <TocIcon />
          </Button>
        ) : (
          <Button
            sx={{ size: 'small', color: 'primary', cursor: 'not-allowed' }}
            onClick={() =>
              toast.error(
                `You can't look at scoring details before the tournament starts`
              )
            }
          >
            <TocIcon sx={{ color: 'lightgray' }} />
          </Button>
        )}
      </TableCell>
      <TableCell>{props.board.avgSeed}</TableCell>
      <TableCell>{epochToLocale(props.board.dateAddedTimestamp)}</TableCell>
      <TableCell align="center">
        {props.board.verified ? <CheckIcon /> : <></>}
      </TableCell>
      <TableCell align="center">
        {props.allowed && props.allowed.indexOf(props.board.id) !== -1 ? (
          <Link to={`/view/${props.board.id}`}>
            <Button size="small" color="primary">
              <VisibilityIcon />
            </Button>
          </Link>
        ) : (
          <Button
            size="small"
            color="primary"
            onClick={() =>
              toast.error(
                `You can't look at other people's brackets before the tournament starts`
              )
            }
          >
            <VisibilityIcon
              sx={{ color: 'lightgray', cursor: 'not-allowed' }}
            />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const Body = (props: {
  boards: BoardType[];
  setShowDetails: any;
  settings: Settings;
  allowed?: string[];
  auth?: boolean;
}) => {
  if (Array.isArray(props.boards)) {
    const allowView =
      props.auth ||
      props.settings.allowView === 1 ||
      (props.settings.allowView === 2 && props.settings.allowEntry === false);

    const sortedBoards = !props.settings.allowEntry
      ? props.boards
      : props.boards
          .slice()
          .sort((a, b) => b.dateAddedTimestamp - a.dateAddedTimestamp);

    return (
      <TableBody>
        {sortedBoards.map((board, index) => (
          <Row
            key={board.id}
            index={index}
            board={board}
            allowView={allowView}
            allowed={props.allowed}
            setShowDetails={props.setShowDetails}
          />
        ))}
      </TableBody>
    );
  }
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center">Loading...</TableCell>
      </TableRow>
    </TableBody>
  );
};

const BoardView = () => {
  const [boards, setBoards] = useRecoilState(boardState);
  const settings = useRecoilValue(settingsState);
  const [auth, setAuth] = useState(false);
  const [showDetails, setShowDetails] = useState<Board | null>(null);
  const [allowed, setAllowed] = useState<string[]>();

  useEffect(() => {
    fetchBoard().then(
      (items) => {
        // setIsLoaded(true);
        setBoards(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
    checkAuth().then(
      (resp) => {
        // setIsLoaded(true);
        setAuth(resp);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
    fetchWagerAllowed().then(
      (items) => {
        // setIsLoaded(true);
        setAllowed(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  return (
    <div className="leaderboard">
      <h2>Leaderboard</h2>
      {auth ? <p>You're here with admin permissions</p> : <></>}
      {showDetails ? (
        <Details
          isOpen={true}
          closePopup={() => setShowDetails(null)}
          board={showDetails}
        />
      ) : (
        <></>
      )}
      {!settings.allowEntry ? (
        <div style={{ paddingBottom: '2rem' }}>
          <Link to="/who-they-got">
            <Button variant="outlined">Who they got?</Button>
          </Link>
        </div>
      ) : null}
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: 1080,
          marginLeft: 'auto',
          marginRight: 'auto',
          overflow: 'auto',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="1"></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Avg. Seed</TableCell>
              <TableCell>Date</TableCell>
              <TableCell width="20" align="center">
                Verified
              </TableCell>
              <TableCell width="20" align="center">
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <Body
            boards={boards}
            setShowDetails={setShowDetails}
            settings={settings}
            allowed={allowed}
            auth={auth}
          />
        </Table>
      </TableContainer>
    </div>
  );
};

export default BoardView;
