import { Box, Grid } from '@mui/material';

import { primaryColor, secondaryColor, tertiaryColor } from '../model/arrays';

const Locked = () => {
  return (
    <Grid
      container
      className="create-bracket"
      sx={{
        alignContent: 'center',
        justifyContent: 'center',
        marginTop: 2,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          width: '40rem',
          borderRadius: '1rem',
          border: 3,
          borderColor: tertiaryColor,
          padding: 2,
          bgcolor: primaryColor,
          boxShadow: '0px 0px 10px #333',
          color: secondaryColor,
        }}
      >
        <h2>the tournament has started</h2>
        <p>While the tournament is going on, new brackets are locked. </p>
        <p>
          The leaderboard below will update as soon as the results of each match
          are entered into our system.
        </p>
        <p>Thanks for participating. </p>
      </Box>
    </Grid>
  );
};

export default Locked;
