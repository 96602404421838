const Prize = () => {
  const style = {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    width: 'fit-content',
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    gap: 45,
  };

  return (
    <div>
      <p style={{ fontWeight: 'bold', marginTop: 2 }}>
        $10 Donation for wrestling
      </p>
      <p>
        * please keep in mind that this is for fun if you can't afford the $10
        donation please don't play. We will do our best, Good luck!
      </p>
      <ul style={style}>
        <li>1st 25%</li>
        <li>2nd 15%</li>
        <li>3rd 10%</li>
      </ul>
    </div>
  );
};

export default Prize;
