import { useRecoilValue } from 'recoil';

import {
  constraintsState,
  selectionState,
  submitState,
} from '../../model/store';
import { countSelectedWeightClasses } from '../../utils/countSelectedWeightClasses';
import {
  SubmitConstraintsNotMet,
  SubmitNotAllWeightclassesSelected,
  SubmitNoWildcardSelected,
} from './SubmitNotReady';
import SubmitReady from './SubmitReady';
import SubmitSuccess from './SubmitSuccess';

const Submit = (props: { noSucess?: boolean }) => {
  const selectedList = useRecoilValue(selectionState);
  const constraints = useRecoilValue(constraintsState);
  const submitted = useRecoilValue(submitState);

  if (!constraints) {
    return <SubmitConstraintsNotMet />;
  }
  if (countSelectedWeightClasses(selectedList) !== 10) {
    return <SubmitNotAllWeightclassesSelected />;
  }
  if (selectedList.wc.seed === 0) {
    return <SubmitNoWildcardSelected />;
  }
  if (submitted) {
    if (props.noSucess) {
      return <></>;
    }
    return <SubmitSuccess submission={submitted} />;
  }
  return <SubmitReady />;
};

export default Submit;
