import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import type { Athlete, Board, Scoring, ScoringDetails } from '../model/models';
import { athletesState } from '../model/store';
import { postCalculateScoreSingle } from '../utils/fetch';

const Row = (props: {
  title: string;
  details: ScoringDetails;
  athletes: Athlete[];
}) => {
  const weight =
    props.title === 'wc' ? props.details.weight?.toString() : props.title;
  const athlete = props.athletes.find(
    (a) => a.seed === props.details.seed && a.weight.toString() === weight
  );
  return (
    <TableRow key={props.title}>
      <TableCell sx={{ fontWeight: 'bold' }}>
        {props.title === 'wc' ? 'Wild Card' : props.title}
      </TableCell>
      <TableCell align="left">{athlete?.seed}</TableCell>
      <TableCell>
        {athlete ? `${athlete.firstname} ${athlete?.lastname}` : ''}
      </TableCell>
      <TableCell>{props.details.placement ?? '-'}</TableCell>
      <TableCell align="right">{props.details?.points.placement}</TableCell>
      <TableCell align="right">{props.details?.points.advancement}</TableCell>
      <TableCell align="right">{props.details?.points.bonus}</TableCell>
      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
        {props.details?.points.total}
      </TableCell>
    </TableRow>
  );
};

type Props = {
  board: Board;
  isOpen: boolean;
  closePopup: any;
};

const Details = (props: Props) => {
  const [scores, setScores] = useState<Scoring | null>();
  const athletes = useRecoilValue(athletesState);

  useEffect(() => {
    postCalculateScoreSingle(props.board.id).then(
      (items) => {
        // setIsLoaded(true);
        setScores(items?.data);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  return (
    <div
      style={{
        display: props.isOpen ? 'block' : 'none',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px #333',
        maxHeight: '80%',
        maxWidth: '80%',
        zIndex: 999,
      }}
    >
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button onClick={props.closePopup}>close</Button>
      </div>
      <h3>Scoring details for {props.board.firstname}'s bracket</h3>
      {scores ? (
        <TableContainer
          component={Paper}
          sx={{
            // minWidth: 650,
            height: '70vh',
            maxHeight: 720,
            maxWidth: 1080,
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowX: 'auto',
            overflowY: 'auto',
          }}
        >
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Weight Class</TableCell>
                <TableCell>Seed</TableCell>
                <TableCell sx={{ width: 120 }}>Athlete</TableCell>
                <TableCell>Place</TableCell>
                <TableCell align="right">Placement Points</TableCell>
                <TableCell align="right">Advancement Points</TableCell>
                <TableCell align="right">Bonus Points</TableCell>
                <TableCell align="right">Total Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(scores).map((k, _v) => {
                const key = k[0];
                const value = k[1];

                if (typeof value === 'number') {
                  return null;
                }
                return <Row title={key} details={value} athletes={athletes} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default Details;
