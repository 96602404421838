import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { weightclasses } from '../../model/arrays';
import type { Verification, Weight } from '../../model/models';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { epochToLocale } from '../../utils/epochToHuman';
import { putNickname, putVerify } from '../../utils/fetch';
import DeleteModal from './DeleteModal';

const WeightCell = (props: {
  weight: Weight | string;
  pick: number | string;
}) => {
  return (
    <Grid container direction="column" border={1} width={'70px'}>
      <Grid item fontWeight={'bold'}>
        {props.weight}
      </Grid>
      <Grid item>#{props.pick}</Grid>
    </Grid>
  );
};

type Props = {
  verification: Verification;
};

const VerificationCard = (props: Props) => {
  const [adminName, setAdminName] = useState(
    props.verification.adminAssignedName ?? ''
  );
  const [verified, setVerified] = useState(props.verification.verified);
  const [showAdminName, setShowAdminName] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const lbs = [
    props.verification.lbs125,
    props.verification.lbs133,
    props.verification.lbs141,
    props.verification.lbs149,
    props.verification.lbs157,
    props.verification.lbs165,
    props.verification.lbs174,
    props.verification.lbs184,
    props.verification.lbs197,
    props.verification.lbs285,
  ];

  const date = props.verification.dateAddedTimestamp
    ? epochToLocale(props.verification.dateAddedTimestamp)
    : new Date();

  function handleClick() {
    const newValue = !verified;
    const res = putVerify(props.verification.id, newValue);
    res.then(
      (result) => {
        // Check if the result is an error object
        if (result instanceof Error) {
          // Check if the error message includes the HTTP status code 422
          if (result.message.includes('422')) {
            // Handle error for HTTP status code 422
            window.location.href = '/login?next=%2Fadmin%2Fverify';
          }
          // Handle other error cases
          else {
            console.error(result.message);
          }
          return false;
        }
        setVerified(newValue);
        return true;
      },
      (_error) => {
        // setIsLoaded(true);
        return false;
        // setError(error);
      }
    );
  }

  useEffect(() => {
    if (adminName) {
      setShowAdminName(true);
    }
  }, []);

  function handleType(event: React.ChangeEvent<HTMLInputElement>) {
    setAdminName(event.target.value);
    putNickname(props.verification.id, event.target.value);
  }

  return (
    <>
      <DeleteModal
        verification={props.verification}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
      <Grid
        container
        direction="row"
        sx={{
          gap: 2,
          border: 2,
          padding: 1,
          marginTop: 1,
          marginBottom: 1,
          width: '870px',
          maxWidth: '100%',
        }}
      >
        <Grid
          container
          sx={{ justifyContent: 'space-between', alignItems: 'center', gap: 1 }}
        >
          <Grid
            container
            sx={{ width: 'auto', alignItems: 'center', flexGrow: 1 }}
          >
            <Grid item>
              <Button
                sx={{
                  height: '40px',
                  width: '55px',
                  color: 'black',
                  marginRight: '10px',
                }}
                onClick={() => handleClick()}
              >
                {verified ? (
                  <CloseIcon htmlColor="red" />
                ) : (
                  <CheckIcon htmlColor="green" />
                )}
              </Button>
            </Grid>
            <Grid item sx={{ fontWeight: 'bold' }}>
              {props.verification.id}
            </Grid>
          </Grid>
          <Grid item>
            <Button size="small" onClick={() => setShowDeleteModal(true)}>
              <DeleteIcon fontSize="small" />
            </Button>
          </Grid>
          <Grid item>
            {date.toLocaleString('default', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: 'space-between',
            backgroundColor: 'lightgray',
            padding: 1,
            borderRadius: 2,
            alignItems: 'center',
          }}
        >
          <Grid
            item
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
            }}
          >
            {props.verification.firstname} {props.verification.lastname}
          </Grid>
          <Grid item onClick={() => setShowAdminName(!showAdminName)}>
            <DriveFileRenameOutlineIcon
              sx={{
                display: 'flex',
                fontSize: 'large',
                color: showAdminName ? 'gray' : 'black',
                cursor: 'pointer',
              }}
            />
          </Grid>
        </Grid>
        {showAdminName ? (
          <Grid
            item
            sx={{
              width: '100%',
              zIndex: 50,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <input
              type="text"
              value={adminName}
              placeholder="admin-internal nickname"
              onChange={(event) => handleType(event)}
              minLength={2}
              maxLength={128}
              pattern="[a-zA-Z]+"
              style={{
                width: '80%',
                padding: 1,
                margin: 2,
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item>
          Contact:{' '}
          <span
            onClick={() => copyToClipboard(props.verification.altContact)}
            style={{
              cursor: 'pointer',
              backgroundColor: 'lightgray',
              padding: '2px',
              borderRadius: 2,
            }}
          >
            {props.verification.altContact}{' '}
            {props.verification.altContact ? (
              <ContentPasteIcon
                sx={{ fontSize: 'small', '&:hover': { color: 'gray' } }}
              />
            ) : (
              'n/a'
            )}
          </span>
        </Grid>
        <Grid container>
          {weightclasses.map((weight, i) => (
            <WeightCell key={weight} weight={weight} pick={lbs[i]} />
          ))}
          <WeightCell
            weight="WC"
            pick={`${props.verification.wcSeed}-${props.verification.wcWeight}`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VerificationCard;
