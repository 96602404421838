import { Grid } from '@mui/material';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { Athlete, FlatBout, WinCondition } from '../model/models';
import { athletesState, flatBracketState } from '../model/store';
import { postCalculateScore, putBout } from '../utils/fetch';

type Props = {
  key: number;
  bout: FlatBout;
  winConditions: WinCondition[];
};

const AdminCard = (props: Props) => {
  const [bracket, setBracket] = useRecoilState(flatBracketState);
  const [local, setLocal] = useState(
    bracket.filter((bout) => bout.bout === props.bout.bout)[0] as FlatBout
  );
  const athletes = useRecoilValue(athletesState);

  const athleteA: Athlete | undefined = athletes.filter(
    (athlete) =>
      athlete.weight === props.bout.weight && athlete.seed === props.bout.boutA
  )[0];
  const athleteB: Athlete | undefined = athletes.filter(
    (athlete) =>
      athlete.weight === props.bout.weight && athlete.seed === props.bout.boutB
  )[0];

  function handleClick(index: number, winnerIsA: boolean) {
    if (!athleteA && winnerIsA) {
      console.log('Clicked questionmark {A}');
      return;
    }
    if (!athleteB && !winnerIsA) {
      console.log('Clicked questionmark {B}');
      return;
    }
    if (athleteA && athleteB) {
      setLocal({
        ...local,
        winner: winnerIsA ? athleteA.seed : athleteB.seed,
        loser: winnerIsA ? athleteB.seed : athleteA.seed,
      });
      return;
    }
    // FIXME: Temporary workaround to allow picking the number 1 seed when 32 vs 33. has no results.
    if (athleteA?.seed === 1 && !athleteB) {
      setLocal({
        ...local,
        winner: athleteA.seed,
        loser: 33,
      });
    } else if (athleteB?.seed === 1 && !athleteA) {
      setLocal({
        ...local,
        winner: athleteB.seed,
        loser: 33,
      });
    }
  }

  function handleClickWinCondition(id: number) {
    // Update state
    setLocal({
      ...local,
      winConditionId: id,
    });
  }

  function apply() {
    const res = putBout(local);
    res.then(
      (result) => {
        // Check if the result is an error object
        if (result instanceof Error) {
          // Check if the error message includes the HTTP status code 422
          if (result.message.includes('422')) {
            // Handle error for HTTP status code 422
            window.location.href = '/login?next=%2Fadmin';
          }
          // Handle other error cases
          else {
            console.error(result.message);
          }
          return false;
        }
        setBracket(
          bracket.map((bout) => (bout.bout === local.bout ? local : bout))
        );
        postCalculateScore();
        return true;
      },
      (_error) => {
        // setIsLoaded(true);
        return false;
        // setError(error);
      }
    );
  }

  return (
    <>
      <Grid
        container
        className={`bout${String(props.bout.bout)}`}
        columns={4}
        sx={{
          direction: 'row',
          justifyContent: 'space-evenly',
          border: 3,
          padding: 1,
          margin: 1,
          borderRadius: 2,
          maxWidth: '730px',
        }}
      >
        <Grid
          item
          sx={{
            width: '10%',
            flexGrow: 1,
            border: 1,
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.bout.bout}
        </Grid>
        <Grid
          item
          sx={{
            width: '10%',
            flexGrow: 1,
            border: 1,
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.bout.weight}
        </Grid>
        <Grid
          container
          className={
            athleteA
              ? `${athleteA.weight}.${athleteA.seed}`
              : props.bout.boutA.toString()
          }
          sx={[
            {
              '&:hover': {
                backgroundColor: 'black',
                color: 'white',
                border: 0,
              },
            },
            {
              width: '40%',
              flexGrow: 1,
              border: local.winner === athleteA?.seed ? 0 : 1,
              padding: 1,
              bgcolor: local.winner === athleteA?.seed ? '#202020' : 'white',
              color: local.winner === athleteA?.seed ? 'white' : 'black',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            },
          ]}
          onClick={() => handleClick(props.key, true)}
        >
          <Grid item style={{ flexGrow: 1, textAlign: 'left' }}>
            {athleteA
              ? `#${athleteA.seed} ${athleteA.firstname} ${athleteA.lastname}`
              : '???'}
          </Grid>
          {athleteA ? <Grid item>[{athleteA.schoolName}]</Grid> : <></>}
        </Grid>
        <Grid
          container
          className={
            athleteB
              ? `${athleteB.weight}.${athleteB.seed}`
              : props.bout.boutB.toString()
          }
          sx={[
            {
              '&:hover': {
                backgroundColor: 'black',
                color: 'white',
                border: 0,
              },
            },
            {
              width: '40%',
              flexGrow: 1,
              border: local.winner === athleteB?.seed ? 0 : 1,
              padding: 1,
              bgcolor: local.winner === athleteB?.seed ? '#202020' : 'white',
              color: local.winner === athleteB?.seed ? 'white' : 'black',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            },
          ]}
          onClick={() => handleClick(props.key, false)}
        >
          <Grid item style={{ flexGrow: 1, textAlign: 'left' }}>
            {athleteB
              ? `#${athleteB.seed} ${athleteB.firstname} ${athleteB.lastname}`
              : '???'}
          </Grid>
          {athleteB ? <Grid item>[{athleteB.schoolName}]</Grid> : <></>}
        </Grid>
        {(athleteA && athleteB) ||
        // FIXME: Another part of the workaround
        (athleteA?.seed === 1 && !athleteB) ||
        (athleteB?.seed === 1 && !athleteA) ? (
          <Grid container>
            <Grid
              item
              sx={{
                width: '100%',
                height: 32,
                flexGrow: 1,
                border: 1,
                padding: 1,
                display: 'flex',
                backgroundColor: 'lightgray',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Win condition {local.winConditionId}
            </Grid>
            {props.winConditions.map((winCondition) => {
              const selected = local.winConditionId === winCondition.id;
              return (
                <Grid
                  item
                  sx={[
                    {
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                      },
                    },
                    {
                      width: '50%',
                      height: 32,
                      flexGrow: 1,
                      border: 1,
                      padding: 1,
                      borderColor: 'black',
                      backgroundColor: selected ? '#202020' : 'white',
                      color: selected ? 'white' : 'black',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    },
                  ]}
                  onClick={() => handleClickWinCondition(winCondition.id)}
                >
                  {winCondition.name}
                </Grid>
              );
            })}
            {local.winner &&
            local.loser &&
            local.winConditionId &&
            (local.winner !== props.bout.winner ||
              local.loser !== props.bout.loser ||
              local.winConditionId !== props.bout.winConditionId) ? (
              <Grid
                item
                sx={[
                  {
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  },
                  {
                    width: '100%',
                    height: 32,
                    border: 1,
                    borderColor: 'black',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                ]}
                onClick={() => apply()}
              >
                Apply
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default AdminCard;
