import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RouteIcon from '@mui/icons-material/Route';
import { ButtonBase } from '@mui/material';
import toast from 'react-hot-toast';
import { useRecoilState, useRecoilValue } from 'recoil';

import { primaryColor } from '../../model/arrays';
import { creationModeState, showInstructionsState } from '../../model/store';

const SwitchCreationMode = () => {
  const [creationMode, setCreationMode] = useRecoilState(creationModeState);
  const showInstructions = useRecoilValue(showInstructionsState);

  function handleClick() {
    if (creationMode === 'bracket') {
      setCreationMode('list');
      toast.success('Selected List Mode');
    } else if (creationMode === 'list') {
      setCreationMode('bracket');
      toast.success('Selected Bracket Mode');
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  if (!creationMode) {
    return <></>;
  }

  return (
    <div
      className="floating-button"
      style={{
        top: '0.5rem',
        left: !showInstructions ? '4rem' : '0.5rem',
        position: 'fixed',
      }}
    >
      <ButtonBase
        sx={{
          height: 50,
          width: 50,
          padding: 0,
          borderRadius: '100%',
          aspectRatio: 1,
          color: primaryColor,
          backgroundColor: '#ededed50',
          zIndex: 9999,
        }}
        onClick={handleClick}
      >
        {creationMode === 'bracket' ? (
          <FormatListNumberedIcon />
        ) : (
          <RouteIcon />
        )}
      </ButtonBase>
    </div>
  );
};

export default SwitchCreationMode;
