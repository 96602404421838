import { Box } from '@mui/material';

import { lightBackground } from '../../model/arrays/colors';
import Prize from './Prize';

const DonationMainScreen = () => {
  return (
    <Box
      sx={{
        backgroundColor: lightBackground,
        width: 'fit-content',
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 1,
        boxShadow: 1,
        borderRadius: 3,
      }}
    >
      <Prize />
    </Box>
  );
};

export default DonationMainScreen;
