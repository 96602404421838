import { Box } from '@mui/material';
import React from 'react';

const Row = (props: {
  title: string;
  hint?: string;
  numerator: number;
  denominator: number;
}) => {
  const counterStyle: React.CSSProperties = {
    backgroundColor: '#f3f3f3',
    width: '2rem',
    borderRadius: 4,
  };

  const warningStyle = {
    backgroundColor: 'red',
    width: '2rem',
    borderRadius: 4,
    color: 'white',
  };

  const warn = props.numerator > props.denominator;

  return (
    <Box sx={{ display: 'flex', padding: '0.1rem' }}>
      <div
        style={
          !warn
            ? {
                flexGrow: 1,
                textAlign: 'left',
              }
            : {
                flexGrow: 1,
                textAlign: 'left',
                textDecoration: 'underline',
                textUnderlineOffset: 1,
                textDecorationColor: 'red',
              }
        }
      >
        {props.title}:
      </div>
      {props.hint ? (
        <div
          style={{ textAlign: 'left', marginLeft: '8px', marginRight: '8px' }}
        >
          ({props.hint})
        </div>
      ) : (
        <></>
      )}
      <div style={!warn ? counterStyle : warningStyle}>{props.numerator}</div>
      <div style={{ marginLeft: 8, marginRight: 8 }}>/</div>
      <div style={counterStyle}>{props.denominator}</div>
    </Box>
  );
};

export default Row;
