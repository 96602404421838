import Config from '../config';
import type {
  Athlete,
  Board,
  Bracket,
  Brackets,
  Conference,
  Contact,
  Event,
  FlatBout,
  FlatBracket,
  FlatResultBracket,
  Login,
  Response,
  Result,
  ResultAthlete,
  ResultBoard,
  ResultBracket,
  ResultBrackets,
  ResultSettingsAllowEntry,
  School,
  Scoring,
  Script,
  Selection,
  Settings,
  SettingsFieldNames,
  Submit,
  Weight,
  WinCondition,
} from '../model/models';
import type { Choice } from '../model/models/Choice';

export async function postLogin(
  username: string,
  password: string
): Promise<Login> {
  try {
    const res = await fetch(`${Config.API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        username,
        password,
      }),
    });
    if (!res.ok) {
      if (res.status === 401) {
        const result = (await res.json()) as Login;
        return result;
      }
      throw new Error(res.statusText);
    }
    const result = (await res.json()) as Login;
    localStorage.setItem('access_token', result.access_token);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function postLogout() {
  // Make a request to the server to check if the user is authenticated
  const response = await fetch(`${Config.API_URL}/logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return true;
  }
  return false;
}

export async function checkAuth() {
  // Make a request to the server to check if the user is authenticated
  const response = await fetch(`${Config.API_URL}/check-auth`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return true;
  }
  return false;
}

export async function fetchAthlete(): Promise<Athlete[]> {
  const link = `${Config.API_URL}/athletes`;
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: ResultAthlete) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );
  const awaited = await Promise.all([promise1]);
  const result = [...awaited[0]];

  // result.sort((a, b) => b.createTime - a.createTime);

  return result;
}

export async function fetchBracket(weightclass: Weight): Promise<Bracket> {
  const link = `${Config.API_URL}/round/all/${weightclass}`;
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: ResultBracket) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  // const result = [...awaited];

  // result.sort((a, b) => b.createTime - a.createTime);

  return awaited[0];
}

export async function fetchBrackets(): Promise<Brackets> {
  const link = `${Config.API_URL}/brackets?consolation=False`;
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: ResultBrackets) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  // const result = [...awaited];

  // result.sort((a, b) => b.createTime - a.createTime);

  return awaited[0];
}

export async function fetchConsolationBracket(): Promise<Brackets> {
  const link = `${Config.API_URL}/brackets?consolation=true`;
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: ResultBrackets) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  // const result = [...awaited];

  // result.sort((a, b) => b.createTime - a.createTime);

  return awaited[0];
}

export async function fetchFlatBracket(): Promise<FlatBracket> {
  const link = `${Config.API_URL}/bracket/flat?sort=true&championship=true&consolation=true`;
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: FlatResultBracket) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  // const result = [...awaited[0]];

  // result.sort((a, b) => b.createTime - a.createTime);

  return awaited[0];
}

export const fetchWinConditions = async (): Promise<WinCondition[]> => {
  try {
    const res = await fetch(`${Config.API_URL}/win-conditions`);
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const json = await res.json();
    return json.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function postSubmit(
  selection: Selection,
  contact: Contact
): Promise<Submit | Error> {
  const res = await fetch(`${Config.API_URL}/wager`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      selection: JSON.stringify(selection),
      firstname: contact.first,
      lastname: contact.last,
      altContact: contact.alt ?? '',
    }),
  });

  if (!res.ok) {
    return new Error(`HTTP Error: ${res.status} ${res.statusText}`);
  }

  const result = await res.json();
  return result.data;
}

export async function fetchSubmit(id: string): Promise<Submit> {
  const link = `${Config.API_URL}/wager?id=${id}`;
  // console.log(link);
  const promise1 = fetch(link, {
    headers: {
      secret: localStorage.getItem('secret') ?? '',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);

  return awaited[0];
}

export async function deleteSubmit(id: string): Promise<any> {
  const link = `${Config.API_URL}/delete-wager?id=${id}`;
  // console.log(link);
  const promise1 = fetch(link, {
    headers: {
      secret: localStorage.getItem('secret') ?? '',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    method: 'DELETE',
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);

  return awaited[0];
}

export async function fetchSettings(field: string): Promise<Boolean | number> {
  const link = `${Config.API_URL}/settings?field=${field}`;
  // console.log(link);
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: ResultSettingsAllowEntry) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  return awaited[0];
}

export async function fetchAllSettings(): Promise<Settings> {
  const link = `${Config.API_URL}/settings`;
  // console.log(link);
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  return awaited[0];
}

export async function postSettings(
  field: SettingsFieldNames,
  value: boolean
): Promise<Boolean | Error> {
  const res = await fetch(`${Config.API_URL}/change-settings?field=${field}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: new URLSearchParams({
      value: String(value),
    }),
    // credentials: 'include'
  });
  if (!res.ok) {
    if (res.status === 422) {
      window.location.href = '/login?next=%2Fadmin';
    }
    return new Error(`HTTP Error: ${res.status} ${res.statusText}`);
  }

  const result = await res.json();
  return result.data;
}

export async function postCalculateScore(): Promise<any> {
  const res = await fetch(`${Config.API_URL}/calculate-score`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    // credentials: 'include'
  });
  if (!res.ok) {
    if (res.status === 422) {
      window.location.href = '/login?next=%2Fadmin';
    }
    return new Error(`HTTP Error: ${res.status} ${res.statusText}`);
  }

  const result = await res.json();
  return result.data;
}

export async function postCalculateScoreSingle(
  wager: string
): Promise<Result<Scoring> | null> {
  const params = new URLSearchParams();
  params.set('wager', wager);
  params.set('debug', 'true');

  const res = await fetch(
    `${Config.API_URL}/calculate-score?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      // credentials: 'include'
    }
  );
  if (res.ok) {
    return res.json();
  }
  return null;
}

export async function postSimulateStart(): Promise<Boolean | Error> {
  const res = await fetch(`${Config.API_URL}/simulate-start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      // 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string
    },
    // credentials: 'include'
  });
  if (!res.ok) {
    if (res.status === 422) {
      window.location.href = '/login?next=%2Fadmin';
    }
    return new Error(`HTTP Error: ${res.status} ${res.statusText}`);
  }

  const result = await res.json();
  return result.data;
}

export async function postSimulateFinish(): Promise<Boolean | Error> {
  const res = await fetch(`${Config.API_URL}/simulate-finish`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      // 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') as string
    },
    // credentials: 'include'
  });
  if (!res.ok) {
    if (res.status === 422) {
      window.location.href = '/login?next=%2Fadmin';
    }
    return new Error(`HTTP Error: ${res.status} ${res.statusText}`);
  }

  const result = await res.json();
  postCalculateScore();
  return result.data;
}

export async function fetchBoard(): Promise<Board[]> {
  const link = `${Config.API_URL}/board`;
  // console.log(link);
  const promise1 = fetch(link)
    .then((res) => res.json())
    .then(
      (result: ResultBoard) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  const result = [...awaited[0]];

  // result.sort((a, b) => b.createTime - a.createTime);

  return result;
}

export async function fetchWagerAllowed(): Promise<string[]> {
  const link = `${Config.API_URL}/wager/allowed`;
  const promise1 = fetch(link, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      secret: localStorage.getItem('secret') ?? '',
    },
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result.data;
      },
      (error) => {
        throw error;
      }
    );

  const awaited = await Promise.all([promise1]);
  const result = [...awaited[0]];

  // result.sort((a, b) => b.createTime - a.createTime);

  return result;
}

export async function putBout(bout: FlatBout): Promise<Boolean | Error> {
  const res = await fetch(
    `${Config.API_URL}/bout/${bout.bout}?winner=${bout.winner}&loser=${bout.loser}&win_condition=${bout.winConditionId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      // credentials: 'include'
    }
  );
  // If the response is ok, the user is authenticated
  if (!res.ok) {
    return new Error(`HTTP Error: ${res.status} ${res.statusText}`);
  }

  const result = await res.json();
  return result.data;
}

export async function fetchVerifications() {
  const response = await fetch(`${Config.API_URL}/verifications`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function putVerify(wager: string, value: boolean) {
  // Params
  const params = new URLSearchParams();
  params.set('wager', wager);
  params.set('value', value.toString());

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/verify-wager?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (!response.ok) {
    return new Error(`HTTP Error: ${response.status} ${response.statusText}`);
  }

  const result = await response.json();
  return result.data;
}

export async function putNickname(id: string, value: string) {
  // Params
  const params = new URLSearchParams();
  params.set('id', id);
  params.set('name', value);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/user-nickname?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function executeScript(script: string) {
  const params = new URLSearchParams();
  params.set('name', script);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/execute-script?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function getScript(): Promise<Response<Script[]> | null> {
  // Fetch
  const response = await fetch(`${Config.API_URL}/get-scripts`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function postScript(
  filename: string
): Promise<Response<Script[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('filename', filename);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/save-script?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createGetConference(): Promise<Response<
  Conference[]
> | null> {
  // Fetch
  const response = await fetch(`${Config.API_URL}/create/conference`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createDeleteConference(
  id: string
): Promise<Response<Conference[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('id', id);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/conference?${params.toString()}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createPostConference(
  conference: Conference
): Promise<Response<Conference[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('displayName', conference.displayName);
  params.set('name', conference.name);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/conference?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createUpdateConference(
  conference: Conference
): Promise<Response<Conference[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('id', conference.id.toString());
  params.set('displayName', conference.displayName);
  params.set('name', conference.name);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/conference?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createGetSchool(): Promise<Response<School[]> | null> {
  // Fetch
  const response = await fetch(`${Config.API_URL}/create/school`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createDeleteSchool(
  id: string
): Promise<Response<School[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('id', id);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/school?${params.toString()}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createPostSchool(
  school: School
): Promise<Response<School[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('displayName', school.displayName);
  params.set('name', school.name);
  if (school.conferenceId) {
    params.set('conferenceId', school.conferenceId.toString());
  }

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/school?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createUpdateSchool(
  school: School
): Promise<Response<School[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('id', school.id.toString());
  params.set('displayName', school.displayName);
  params.set('name', school.name);
  if (school.conferenceId) {
    params.set('conferenceId', school.conferenceId.toString());
  }

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/school?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createGetAthlete(): Promise<Response<Athlete[]> | null> {
  // Fetch
  const response = await fetch(`${Config.API_URL}/create/athlete`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createDeleteAthlete(
  id: string,
  bumpEveryone: boolean
): Promise<Response<Athlete[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('id', id);
  params.set('bump', bumpEveryone.toString());

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/athlete?${params.toString()}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createPostAthlete(
  athlete: Athlete
): Promise<Response<Athlete[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('weight', athlete.weight.toString());
  params.set('seed', athlete.seed.toString());
  params.set('firstname', athlete.firstname);
  params.set('lastname', athlete.lastname);
  params.set('schoolId', athlete.schoolId.toString());
  params.set('wins', athlete.wins.toString());
  params.set('losses', athlete.losses.toString());

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/athlete?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function createUpdateAthlete(
  athlete: Athlete
): Promise<Response<Athlete[]> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('id', athlete.id.toString());
  params.set('firstname', athlete.firstname);
  params.set('lastname', athlete.lastname);
  params.set('schoolId', athlete.schoolId.toString());
  params.set('wins', athlete.wins.toString());
  params.set('losses', athlete.losses.toString());

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/create/athlete?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function fetchEvent(): Promise<Result<Event>> {
  const link = `${Config.API_URL}/event`;
  // console.log(link);
  const promise1 = await fetch(link)
    .then((res) => res.json())
    .then(
      (result: Result<Event>) => {
        return result;
      },
      (error) => {
        throw error;
      }
    );

  return promise1;
}

export async function getEvents(): Promise<Response<Event[]> | null> {
  // Fetch
  const response = await fetch(`${Config.API_URL}/events`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function postEvents(
  name: string
): Promise<Response<Event> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('name', name);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/events?${params.toString()}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function putEvents(name: string): Promise<Response<Event> | null> {
  // Params
  const params = new URLSearchParams();
  params.set('name', name);

  // Fetch
  const response = await fetch(
    `${Config.API_URL}/events?${params.toString()}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  );
  // If the response is ok, the user is authenticated
  if (response.ok) {
    return response.json();
  }
  return null;
}

export async function getPicks(
  weight: Weight | 'WC'
): Promise<Response<Choice[]> | null> {
  const response = await fetch(`${Config.API_URL}/picks/${weight}`, {
    method: 'GET',
  });
  if (response.ok) {
    return response.json();
  }
  return null;
}
