import { Box, Button, Grid } from '@mui/material';

import { primaryColor } from '../../model/arrays';
import type { Submit } from '../../model/models';
import { copyToClipboard } from '../../utils/copyToClipboard';
import DonationInfo from '../Donation/DonationInfo';

const SubmitSuccess = (props: { submission: Submit }) => {
  return (
    <Grid container className="submitted" sx={{ justifyContent: 'center' }}>
      <Box
        sx={{
          width: '45rem',
          borderRadius: '1rem',
          border: 2,
          borderColor: primaryColor,
          bgcolor: 'lightgray',
          padding: 2,
          margin: 2,
        }}
        // sx={{ backgroundColor: 'secondary.dark', padding: 2}}
      >
        <p>You entered the competition.</p>
        <p>Please write down the following code.</p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => copyToClipboard(props.submission.id)}
        >
          {props.submission.id} 📋
        </Button>
        <p>
          When you donate, please include this code in the notes. This makes it
          easier for us to confirm your entry.
        </p>
        <DonationInfo />
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          sx={{ margin: 1 }}
        >
          Done
        </Button>
      </Box>
    </Grid>
  );
};

export default SubmitSuccess;
