import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import BoardView from '../components/BoardView';
import Bracket from '../components/Bracket';
import { ModeCookies, NameCookies } from '../components/Cookies';
import CreateBracketView from '../components/CreateBracket';
import DonationMainScreen from '../components/Donation/MainScreen';
import ShowInstructions from '../components/FloatingButtons/ShowInstructions';
import StartOver from '../components/FloatingButtons/StartOver';
import SwitchCreationMode from '../components/FloatingButtons/SwitchCreationMode';
import Locked from '../components/Locked';
import Subtitle from '../components/Subtitle';
import TieBreakers from '../components/TieBreakers';
import Title from '../components/Title';
import config from '../config';
import {
  athletesState,
  bracketsState,
  consolationBracketsState,
  modeState,
  settingsState,
} from '../model/store';
import {
  fetchAllSettings,
  fetchAthlete,
  fetchBrackets,
  fetchConsolationBracket,
} from '../utils/fetch';

const Main = () => {
  const mode = useRecoilValue(modeState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const [brackets, setBrackets] = useRecoilState(bracketsState);
  const setConsolationBrackets = useSetRecoilState(consolationBracketsState);
  const [athletes, setAthletes] = useRecoilState(athletesState);

  document.title = config.TITLE;
  useEffect(() => {
    fetchAllSettings().then(
      // setIsLoaded(true);
      (result) => {
        setSettings(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchBrackets().then(
      (result) => {
        // setIsLoaded(true);
        setBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchConsolationBracket().then(
      (result) => {
        // setIsLoaded(true);
        setConsolationBrackets(result);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  useEffect(() => {
    fetchAthlete().then(
      (items) => {
        // setIsLoaded(true);
        setAthletes(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
  }, []);

  if (mode === 'create') {
    return (
      <>
        <StartOver />
        <ShowInstructions />
        <SwitchCreationMode />
        {brackets !== undefined && athletes !== undefined ? <Bracket /> : <></>}
      </>
    );
  }
  // Undecided
  if (settings.allowEntry === true) {
    return (
      <>
        <Title />
        <Subtitle />
        <ModeCookies />
        <NameCookies />
        <CreateBracketView />
        <DonationMainScreen />
        <BoardView />
        <TieBreakers />
      </>
    );
  }
  return (
    <>
      <Title />
      <Subtitle />
      <ModeCookies />
      <NameCookies />
      <Locked />
      <DonationMainScreen />
      <BoardView />
      <TieBreakers />
    </>
  );
};

export default Main;
