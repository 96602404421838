/* eslint-disable no-nested-ternary */
import type { SxProps } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useRecoilValue } from 'recoil';

import config from '../config';
import type { Bout, Round, Weight } from '../model/models';
import {
  athletesState,
  consolationBracketsState,
  modeState,
  selectedWeightState,
  selectionState,
} from '../model/store';
import AthleteCard from './AthleteCard';

const Bracket = (props: { round: Round; sx: SxProps }) => {
  const mode = useRecoilValue(modeState);
  const selectedList = useRecoilValue(selectionState);
  const selectedWeight = useRecoilValue(selectedWeightState);
  const athletes = useRecoilValue(athletesState);
  const selected = selectedList.weights[selectedWeight as unknown as Weight];

  const roundwidth = 500;

  const athletesInWeight = athletes.filter(
    (athlete) => athlete.weight === selectedWeight
  );

  if (Array.isArray(props.round)) {
    const inner = props.round.map((bout: Bout, index) => (
      <Grid
        container
        direction="column"
        className="bout"
        sx={{
          marginTop: 4,
          marginBottom: 4,
          marginLeft: 2,
          marginRight: 6,
          padding: 0,
        }}
      >
        <AthleteCard
          isA={true}
          bout={bout}
          selected={selected}
          mode={mode}
          athletesInWeight={athletesInWeight}
        />
        <Grid
          container
          justifyContent="space-between"
          sx={{ paddingLeft: 7.25, paddingRight: 2.25 }}
        >
          <Grid sx={{ marginTop: 0.5, marginBottom: 0.5 }}>
            <i>
              {props.round.length !== 3
                ? `bout ${bout.bout}`
                : `${
                    index === 0
                      ? '3rd place'
                      : index === 1
                      ? '5th place'
                      : '7th place'
                  } (${bout.bout})`}
            </i>
          </Grid>
          {bout.winCondition !== undefined && bout.winCondition !== null ? (
            <Grid sx={{ marginTop: 0.5, marginBottom: 0.5 }}>
              {`${bout.winCondition.name} (${bout.winCondition.points})`}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <AthleteCard
          isA={false}
          bout={bout}
          selected={selected}
          mode={mode}
          athletesInWeight={athletesInWeight}
        />
      </Grid>
    ));
    return (
      <Grid
        container
        maxWidth={roundwidth}
        alignContent="space-around"
        sx={props.sx}
      >
        {inner}
      </Grid>
    );
  }
  return <></>;
};

const ConsolationView = (props: { children?: React.ReactNode }) => {
  const selectedWeight = useRecoilValue(selectedWeightState);
  const br = useRecoilValue(consolationBracketsState)[selectedWeight];
  const athletes = useRecoilValue(athletesState);
  const roundwidth = 500;

  if (
    br === undefined ||
    br === null ||
    athletes.length === undefined ||
    br.round6 === undefined ||
    br.round7 === undefined
  ) {
    return (
      <>
        <p>loading...</p>
      </>
    );
  }

  return (
    <div className="consolation-bracket">
      <Box
        sx={{ flexGrow: 1, backgroundColor: '#f9f9f9', overflowY: 'auto' }}
        width={'100%'}
      >
        <Typography
          variant={'h3'}
          fontSize={18}
          fontFamily={'inherit'}
          sx={{
            width: '200px',
            marginTop: 2,
            position: 'absolute',
            left: '50%',
            right: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {`${selectedWeight} lbs weight class`}
        </Typography>
        <Grid
          container
          direction="row"
          flexWrap="nowrap"
          width={roundwidth * (config.BRACKET_SIZE === 33 ? 7 : 5)}
        >
          {config.BRACKET_SIZE === 33 ? (
            <>
              <Bracket round={br.round1} sx={{ marginBottom: 9 }} />
              <Bracket
                round={br.round2}
                sx={{ marginTop: 3, marginBottom: 5 }}
              />
              <Bracket
                round={br.round3}
                sx={{ marginTop: 4, marginBottom: 5 }}
              />
              <Bracket
                round={br.round4}
                sx={{ marginTop: 10, marginBottom: 2 }}
              />
              <Bracket
                round={br.round5}
                sx={{ marginTop: 10, marginBottom: 2 }}
              />
              <Bracket
                round={br.round6}
                sx={{ marginTop: 17, marginBottom: 0 }}
              />
              <Bracket
                round={br.round7}
                sx={{ marginTop: 90, marginBottom: 2 }}
              />
            </>
          ) : (
            <>
              <Bracket
                round={br.round3}
                sx={{ marginTop: 9, marginBottom: 0 }}
              />
              <Bracket
                round={br.round4}
                sx={{ marginTop: 0, marginBottom: 8 }}
              />
              <Bracket
                round={br.round5}
                sx={{ marginTop: 2, marginBottom: 10 }}
              />
              <Bracket
                round={br.round6}
                sx={{ marginTop: 0, marginBottom: 16 }}
              />
              <Bracket
                round={br.round7}
                sx={{ marginTop: 30, marginBottom: 2 }}
              />
            </>
          )}
        </Grid>
        {props.children}
      </Box>
    </div>
  );
};

export default ConsolationView;
