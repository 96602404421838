import Casino from '@mui/icons-material/Casino';
import { Box } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';

import config from '../../config';
import {
  primaryColor,
  secondaryColor,
  tertiaryColor,
} from '../../model/arrays';
import type { Athlete } from '../../model/models';
import { selectionState } from '../../model/store';
import { clickedAthlete } from '../../utils/clickedAthlete';
import { clickedWildcard } from '../../utils/clickedWildcard';

const AthleteCard = (props: { athlete: Athlete }) => {
  const [selectedList, setSelectedList] = useRecoilState(selectionState);

  const isSelected =
    selectedList.weights[props.athlete.weight] === props.athlete.seed;

  const isSelectedWildcard =
    selectedList.wc.weight === props.athlete.weight &&
    selectedList.wc.seed === props.athlete.seed;

  return (
    <Box
      sx={{
        display: 'flex',
        margin: 1,
        padding: 1,
        border: 1,
        borderColor: !isSelected ? tertiaryColor : primaryColor,
        alignItems: 'center',
        borderRadius: 2,
        gap: 1,
        cursor: 'pointer',
      }}
      onClick={() =>
        clickedAthlete(
          props.athlete.weight,
          props.athlete.seed,
          selectedList,
          setSelectedList,
          undefined,
          true
        )
      }
    >
      <Box
        sx={{
          backgroundColor: !isSelected ? primaryColor : tertiaryColor,
          color: secondaryColor,
          fontSize: 32,
          height: 28,
          width: 28,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '4px',
        }}
      >
        {props.athlete.seed}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexGrow: 1,
        }}
      >
        <Box sx={{ fontWeight: 'bold', fontSize: 18, textAlign: 'left' }}>
          {props.athlete.firstname} {props.athlete.lastname}
        </Box>
        <Box>
          {props.athlete.schoolName} ({props.athlete.conferenceName})
        </Box>
      </Box>
      {props.athlete.seed >= config.WILDCARD_MIN_SEED && !isSelected ? (
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Casino
            fontSize="large"
            sx={{
              color: !isSelectedWildcard ? primaryColor : tertiaryColor,
              zIndex: 100,
              cursor: 'pointer',
            }}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              clickedWildcard(
                props.athlete.weight,
                props.athlete.seed,
                selectedList,
                setSelectedList
              );
            }}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: '40px',
          flexShrink: 0,
        }}
      >
        <Box>W: {props.athlete.wins}</Box>
        <Box>L: {props.athlete.losses}</Box>
      </Box>
    </Box>
  );
};

export default AthleteCard;
