import HelpIcon from '@mui/icons-material/Help';
import { ButtonBase } from '@mui/material';
import { useRecoilState } from 'recoil';

import { primaryColor } from '../../model/arrays';
import { showInstructionsState } from '../../model/store';

const ShowInstructions = () => {
  const [show, setShow] = useRecoilState(showInstructionsState);

  function handleClick() {
    setShow(!show);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  if (show) {
    return <></>;
  }

  return (
    <div
      className="floating-button"
      style={{ top: '0.5rem', left: '0.5rem', position: 'fixed' }}
    >
      <ButtonBase
        sx={{
          height: 50,
          width: 50,
          padding: 0,
          borderRadius: '100%',
          aspectRatio: 1,
          color: primaryColor,
          backgroundColor: '#ededed50',
          zIndex: 9999,
        }}
        onClick={handleClick}
      >
        <HelpIcon />
      </ButtonBase>
    </div>
  );
};

export default ShowInstructions;
