import CasinoIcon from '@mui/icons-material/Casino';
import { Box, Button } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import config from '../../config';
import { primaryColor } from '../../model/arrays';
import {
  athletesState,
  bracketsState,
  modeState,
  showInstructionsState,
} from '../../model/store';
import AthleteCard from '../AthleteCard';
import Constraints from '../Constraints/Constraints';

const InstructionsBracketCreate = () => {
  const weight = 125;
  const mode = useRecoilValue(modeState);
  const br = useRecoilValue(bracketsState)[weight];
  const athletes = useRecoilValue(athletesState);
  const [show, setShow] = useRecoilState(showInstructionsState);

  const bout = br?.round1.find((b) => b.boutA < 8);

  if (!show || !athletes || !athletes[0] || !athletes[0].seed || !bout) {
    return <></>;
  }

  const athletesInWeight = athletes.filter(
    (athlete) => athlete.weight === weight
  );

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        borderRadius: 3,
        marginBottom: 3,
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '800px',
        maxWidth: '95%',
        border: '3px solid',
        borderColor: 'black',
        overflowX: 'auto',
        position: 'relative',
        boxShadow: 3,
      }}
    >
      <div style={{ margin: 12 }}>
        <h3 style={{ marginBottom: 0, marginTop: 0 }}>Instructions</h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <p>1. Select one of the athletes below.</p>
          </div>
          <div style={{ width: 400, marginLeft: 25, marginRight: 25 }}>
            <AthleteCard
              isA={true}
              bout={bout}
              selected={0}
              mode={mode}
              athletesInWeight={athletesInWeight}
              deactivateClick={true}
              hideWildcard={true}
            />
          </div>
          <div>
            <p>
              2. The selected athlete looks like this. Do this for all 10 weight
              classes.
            </p>
          </div>
          <div style={{ width: 400 }}>
            <AthleteCard
              isA={true}
              bout={bout}
              selected={bout.boutA}
              mode={mode}
              athletesInWeight={athletesInWeight}
              deactivateClick={true}
              hideWildcard={true}
            />
          </div>
          <div>
            <p>
              3. The constraints are shown in the bottom right hand corner, they
              will turn red when exceeded. These are not requirements, only
              contraints.
              <br />
              Example: You don't need to pick 3 top 3 seeds, you just can't pick
              4 or more.
            </p>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                margin: -8,
              }}
            >
              <Constraints />
            </div>
          </div>
          <div>
            <p>
              4. <span style={{ fontWeight: 'bold' }}>Wildcard</span>: Select
              one wildcard, by clicking the symbol{' '}
              <CasinoIcon
                style={{
                  color: primaryColor,
                  cursor: 'pointer',
                  fontSize: 'inherit',
                }}
              />
              . This can be any seed greater than {config.WILDCARD_MIN_SEED - 1}
              .
            </p>
          </div>
          <div>
            <p>
              5. After you submitted your bracket, please make your donation to
              get your bracket verified.
            </p>
          </div>
          <Button
            size={'large'}
            variant={'outlined'}
            onClick={() => setShow(false)}
          >
            close
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default InstructionsBracketCreate;
