import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

const FloatWrapper = (props: { children: ReactNode }) => {
  const [moveToTop, setMoveToTop] = useState(false);

  function getMaxWindowScrollPosition(): number {
    return (
      Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      ) - window.innerHeight
    );
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const targetHeight = getMaxWindowScrollPosition() * 0.95;
      if (window.innerWidth < 850 && scrollPosition > targetHeight) {
        setMoveToTop(true);
      } else {
        setMoveToTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      style={
        moveToTop
          ? {
              position: 'fixed',
              top: '9rem',
              right: '2.1rem',
              transform: 'translateY(-50%)',
              transition: 'transform 0.3s ease-out',
            }
          : {
              position: 'fixed',
              bottom: '2rem',
              right: '2.1rem',
              transform: 'translateY(0)',
              transition: 'transform 0.3s ease-out',
            }
      }
    >
      {props.children}
    </div>
  );
};

export default FloatWrapper;
