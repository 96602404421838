import CasinoIcon from '@mui/icons-material/Casino';
import { Grid } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';

import config from '../config';
import { primaryColor, secondaryColor, tertiaryColor } from '../model/arrays';
import type { Athlete, Bout, Modes, Weight } from '../model/models';
import { selectedWeightState, selectionState } from '../model/store';
import { clickedAthlete } from '../utils/clickedAthlete';
import { clickedWildcard } from '../utils/clickedWildcard';
import findAthleteBySeed from '../utils/findAthleteBySeed';

type Props = {
  isA: boolean;
  bout: Bout;
  selected: number;
  mode: Modes;
  athletesInWeight: Athlete[];
  deactivateClick?: boolean;
  hideWildcard?: boolean;
};

function getCursor(mode: Modes, seed: number) {
  if (seed) {
    if (mode === 'create' && seed > 0) {
      return 'pointer';
    }
    return 'alias';
  }
  return 'text';
}

const WildcardCard = (props: {
  selectedWeight: Weight;
  seed: number;
  mode: Modes;
  boutNumber: number;
  forceInvisible?: boolean;
}) => {
  const [selectedList, setSelectedList] = useRecoilState(selectionState);

  const isSelected =
    props.selectedWeight === selectedList.wc.weight &&
    props.seed === selectedList.wc.seed;

  return props.mode === 'create' ? (
    <Grid
      item
      width={40}
      sx={{
        color: isSelected ? tertiaryColor : primaryColor,
        marginTop: 'auto',
        marginBottom: 'auto',
        cursor: 'pointer',
      }}
      onClick={() =>
        clickedWildcard(
          props.selectedWeight,
          props.seed,
          selectedList,
          setSelectedList,
          props.boutNumber
        )
      }
    >
      {props.seed >= config.WILDCARD_MIN_SEED && !props.forceInvisible ? (
        <CasinoIcon />
      ) : (
        <></>
      )}
    </Grid>
  ) : (
    <Grid
      item
      width={40}
      sx={{
        color: primaryColor,
        marginTop: 'auto',
        marginBottom: 'auto',
        cursor: 'alias',
      }}
    >
      {isSelected ? <CasinoIcon /> : <></>}
    </Grid>
  );
};

const AthleteCard = (props: Props) => {
  const athlete = findAthleteBySeed(
    props.athletesInWeight,
    props.isA ? props.bout.boutA : props.bout.boutB
  );
  const [selectedList, setSelectedList] = useRecoilState(selectionState);
  const selectedWeight = useRecoilValue(selectedWeightState);
  const seed = props.isA ? props.bout.boutA : props.bout.boutB;

  const isSelected = seed === props.selected && props.selected !== 0;

  return (
    <div style={{ display: 'flex' }}>
      <Grid
        container
        direction="row"
        className="athlete"
        onClick={() =>
          String(props.mode) === 'create' && !props.deactivateClick
            ? clickedAthlete(
                selectedWeight,
                seed,
                selectedList,
                setSelectedList,
                props.bout.bout
              )
            : {}
        }
        sx={{
          borderRadius: isSelected ? 0 : 2,
          borderColor: isSelected ? primaryColor : tertiaryColor,
          border: seed === props.bout.winner && props.bout.winner !== 0 ? 5 : 2,
          boxShadow: 4,
          bgcolor: isSelected ? tertiaryColor : secondaryColor,
          color: isSelected ? primaryColor : tertiaryColor,
          padding: 1,
          justifyContent: 'space-between',
          cursor: getCursor(props.mode, seed),
        }}
      >
        <Grid
          width={40}
          justifyContent="space-between"
          sx={{
            borderRadius: 1,
            bgcolor: isSelected ? secondaryColor : primaryColor,
            color: isSelected ? primaryColor : secondaryColor,
          }}
        >
          {athlete?.seed}
        </Grid>
        <Grid
          width={180}
          sx={{
            fontWeight: 'bold',
            textAlign: 'left',
            paddingLeft: 1,
            textDecoration:
              seed !== props.bout.winner && props.bout.winner !== 0
                ? 'line-through'
                : '',
            textDecorationThickness: '3px',
          }}
        >
          {athlete?.firstname} {athlete?.lastname}
        </Grid>
        <Grid width={100} alignContent={'right'}>
          {athlete?.schoolName}/{athlete?.conferenceName}
        </Grid>
        <Grid width={50}>
          {athlete?.wins}
          {athlete?.wins ? '-' : ''}
          {athlete?.losses}
        </Grid>
      </Grid>
      {!props.hideWildcard ? (
        <WildcardCard
          selectedWeight={selectedWeight}
          seed={seed}
          mode={props.mode}
          boutNumber={props.bout.bout}
          forceInvisible={isSelected}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AthleteCard;
