import TocIcon from '@mui/icons-material/Toc';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import config from '../config';
import type {
  Athlete,
  Board,
  Board as BoardType,
  Settings,
  Weight,
} from '../model/models';
import type { Choice } from '../model/models/Choice';
import {
  athletesState,
  boardState,
  selectedWhoTheyGotWeightState,
  settingsState,
} from '../model/store';
// import Submission from "../controller/GetSubmit";
import {
  checkAuth,
  fetchBoard,
  fetchWagerAllowed,
  getPicks,
} from '../utils/fetch';
import Details from './Details';
import WhoTheyGotWeightButtonsView from './WhoTheyGotWeightButtons';

const Row = (props: {
  board: Board;
  index: number;
  allowView: boolean;
  allowed?: string[];
  setShowDetails: any;
  choices: Choice[];
  athletes: Athlete[];
  selectedWeight: Weight | 'WC';
}) => {
  function getChosenAthlete(): Athlete | null {
    if (props.selectedWeight === 'WC') {
      const choice = props.choices.find((c) => c.wagerId === props.board.id);
      if (choice) {
        return (
          props.athletes.find(
            (athlete) =>
              athlete.weight === choice.weightclass &&
              athlete.seed === choice.seed
          ) || null
        );
      }
      return null;
    }
    const choice = props.choices.find((c) => c.wagerId === props.board.id);
    if (choice?.weightclass !== props.selectedWeight) {
      return null;
    }
    console.log('🚀 ~ getChosenAthlete ~ choice:', choice);
    const selectedAthlete = props.athletes.find(
      (athlete) =>
        athlete.weight === props.selectedWeight && athlete.seed === choice?.seed
    );

    return selectedAthlete || null;
  }

  const chosenAthlete = getChosenAthlete();

  return (
    <TableRow key={props.board.id}>
      <TableCell>{props.index + 1}</TableCell>
      <TableCell>
        {props.board.firstname} {props.board.lastname}
      </TableCell>
      <TableCell>{props.board.score}</TableCell>
      <TableCell>
        {chosenAthlete ? (
          <>
            #{chosenAthlete.seed}{' '}
            {props.selectedWeight === 'WC' ? chosenAthlete.weight : ''}{' '}
            {chosenAthlete.firstname} {chosenAthlete.lastname}{' '}
            <span style={{ fontStyle: 'italic' }}>
              ({chosenAthlete.schoolName})
            </span>
          </>
        ) : null}
      </TableCell>
      <TableCell>
        {props.allowView ? (
          <Button
            sx={{ size: 'small', color: 'primary' }}
            onClick={() => props.setShowDetails(props.board)}
          >
            <TocIcon />
          </Button>
        ) : (
          <Button
            sx={{ size: 'small', color: 'primary', cursor: 'not-allowed' }}
            onClick={() =>
              toast.error(
                `You can't look at scoring details before the tournament starts`
              )
            }
          >
            <TocIcon sx={{ color: 'lightgray' }} />
          </Button>
        )}
      </TableCell>
      <TableCell align="center">
        {props.allowed && props.allowed.indexOf(props.board.id) !== -1 ? (
          <Link to={`/view/${props.board.id}`}>
            <Button size="small" color="primary">
              <VisibilityIcon />
            </Button>
          </Link>
        ) : (
          <Button
            size="small"
            color="primary"
            onClick={() =>
              toast.error(
                `You can't look at other people's brackets before the tournament starts`
              )
            }
          >
            <VisibilityIcon
              sx={{ color: 'lightgray', cursor: 'not-allowed' }}
            />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const Body = (props: {
  boards: BoardType[];
  setShowDetails: any;
  settings: Settings;
  allowed?: string[];
  auth?: boolean;
  choices: Choice[];
  athletes: Athlete[];
  selectedWeight: Weight | 'WC';
}) => {
  if (Array.isArray(props.boards)) {
    const allowView =
      props.auth ||
      props.settings.allowView === 1 ||
      (props.settings.allowView === 2 && props.settings.allowEntry === false);

    const sortedBoards = !props.settings.allowEntry
      ? props.boards
      : props.boards
          .slice()
          .sort((a, b) => b.dateAddedTimestamp - a.dateAddedTimestamp);

    return (
      <TableBody>
        {sortedBoards.map((board, index) => (
          <Row
            key={board.id}
            index={index}
            board={board}
            allowView={allowView}
            allowed={props.allowed}
            setShowDetails={props.setShowDetails}
            choices={props.choices}
            selectedWeight={props.selectedWeight}
            athletes={props.athletes}
          />
        ))}
      </TableBody>
    );
  }
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center">Loading...</TableCell>
      </TableRow>
    </TableBody>
  );
};

const WhoTheyGotBoardView = () => {
  const [boards, setBoards] = useRecoilState(boardState);
  const settings = useRecoilValue(settingsState);
  const [auth, setAuth] = useState(false);
  const [showDetails, setShowDetails] = useState<Board | null>(null);
  const [allowed, setAllowed] = useState<string[]>();
  const [choices, setChoices] = useState<Choice[]>([]);
  const athletes = useRecoilValue(athletesState);
  const selectedWeight = useRecoilValue(selectedWhoTheyGotWeightState);

  useEffect(() => {
    fetchBoard().then(
      (items) => {
        // setIsLoaded(true);
        setBoards(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
    checkAuth().then(
      (resp) => {
        // setIsLoaded(true);
        setAuth(resp);
      },
      (_error) => {
        // setIsLoaded(true);
        // setError(error);
      }
    );
    fetchWagerAllowed().then(
      (items) => {
        // setIsLoaded(true);
        setAllowed(items);
      },
      (_error) => {
        // setIsLoaded(true);
        // sasync etError(error);
      }
    );
  }, []);

  useEffect(() => {
    setChoices([]);
    getPicks(selectedWeight).then((data) => {
      if (data?.data) {
        setChoices(data.data);
      }
    });
  }, [selectedWeight]);

  document.title = `Who they got? | ${config.TITLE}`;

  return (
    <div className="leaderboard">
      <h2>Leaderboard</h2>
      {auth ? <p>You're here with admin permissions</p> : <></>}
      {showDetails ? (
        <Details
          isOpen={true}
          closePopup={() => setShowDetails(null)}
          board={showDetails}
        />
      ) : (
        <></>
      )}
      <div style={{ paddingBottom: '2rem' }}>
        <Link to="/">
          <Button variant="outlined">Home</Button>
        </Link>
      </div>
      <WhoTheyGotWeightButtonsView />
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: 1080,
          marginLeft: 'auto',
          marginRight: 'auto',
          overflow: 'auto',
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="1"></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>
                <code>
                  {selectedWeight !== 'WC' ? selectedWeight : 'Wildcard'}
                </code>{' '}
                Pick
              </TableCell>
              <TableCell>Details</TableCell>
              <TableCell width="20" align="center">
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <Body
            boards={boards}
            setShowDetails={setShowDetails}
            settings={settings}
            allowed={allowed}
            auth={auth}
            choices={choices}
            athletes={athletes}
            selectedWeight={selectedWeight}
          />
        </Table>
      </TableContainer>
    </div>
  );
};

export default WhoTheyGotBoardView;
