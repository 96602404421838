import { Casino } from '@mui/icons-material';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';

import { primaryColor } from '../../model/arrays';
import { selectionState } from '../../model/store';
import { countSelectedWeightClasses } from '../../utils/countSelectedWeightClasses';

const SubmitConstraintsNotMet = () => {
  return (
    <Button
      onClick={() => toast.error('Constraints not met. Check the box.')}
      variant="outlined"
      color="info"
      sx={{ paddingLeft: 6, paddingRight: 6, margin: 1 }}
    >
      Constraints not met
    </Button>
  );
};

function wildcardToast() {
  toast(() => (
    <span style={{ display: 'flex' }}>
      <Casino
        sx={{
          color: primaryColor,
          fontSize: 'large',
          marginRight: '0.5rem',
        }}
      />{' '}
      Pick a wildcard
    </span>
  ));
}

const SubmitNoWildcardSelected = () => {
  return (
    <Button
      onClick={() => wildcardToast()}
      variant="outlined"
      color="info"
      sx={{ paddingLeft: 6, paddingRight: 6, margin: 1 }}
    >
      Pick a wild card
    </Button>
  );
};

const SubmitNotAllWeightclassesSelected = () => {
  const selectedList = useRecoilValue(selectionState);

  function reportMissing() {
    Object.entries(selectedList.weights)
      .filter(([_k, v]) => v === 0)
      .reverse()
      .map(([k, _v]) =>
        toast.error(`Select an athlete in the ${k} lbs weight class`)
      );
    if (selectedList.wc.seed === 0) {
      wildcardToast();
    }
  }

  return (
    <>
      <Button
        onClick={() => reportMissing()}
        variant="outlined"
        color="info"
        sx={{ paddingLeft: 6, paddingRight: 6, margin: 1 }}
      >
        {`${10 - countSelectedWeightClasses(selectedList)} weight classes ${
          !(selectedList.wc.seed > 0) ? '+ wildcard' : ''
        } left to
          pick`}
      </Button>
    </>
  );
};

export {
  SubmitConstraintsNotMet,
  SubmitNotAllWeightclassesSelected,
  SubmitNoWildcardSelected,
};
