import { useEffect, useState } from 'react';

import config from '../config';
import { fetchEvent } from '../utils/fetch';

const Subtitle = () => {
  const [subtitle, setSubtitle] = useState('');

  if (subtitle) {
    document.title = `${subtitle} | ${config.TITLE}`;
  }

  useEffect(() => {
    async function fetchData() {
      const subtitleData = await fetchEvent();
      setSubtitle(subtitleData.data.name);
    }

    fetchData();
  }, []);

  return <h2 style={{ marginTop: 0, marginBottom: 0 }}>{subtitle}</h2>;
};

export default Subtitle;
