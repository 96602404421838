import { Box, Button, Grid, Modal, Typography } from '@mui/material';

import type { Verification } from '../../model/models';
import { deleteSubmit } from '../../utils/fetch';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  maxWidth: '80vw',
  p: 4,
};

const DeleteModal = ({
  verification,
  showDeleteModal,
  setShowDeleteModal,
}: {
  verification: Verification;
  showDeleteModal: boolean;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  async function handleClick() {
    await deleteSubmit(verification.id);
    window.location.reload();
  }

  return (
    <Modal
      open={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Please confirm that you want to delete this entry.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          This action is permanent, it can't be undone.
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ my: 2, fontWeight: 'bold' }}
        >
          {verification.firstname} {verification.lastname} (
          {new Date(verification.dateAdded).toLocaleString('default', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
          )
        </Typography>
        <Grid sx={{ gap: 3 }} container>
          <Grid item>
            <Button onClick={handleClick}>Confirm delete</Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => setShowDeleteModal(false)}
              variant="contained"
            >
              cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
