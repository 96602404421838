import { Button, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';

import { primaryColor } from '../model/arrays/colors';
import type { Event, SettingsFieldNames } from '../model/models';
import { settingsState } from '../model/store';
import {
  getEvents,
  postCalculateScore,
  postEvents,
  postSettings,
  postSimulateFinish,
  postSimulateStart,
  putEvents,
} from '../utils/fetch';

type Props = {
  children: React.ReactNode;
  fn: () => void;
  description: string;
};

const Action = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  async function handleFunction() {
    setLoading(true);
    await props.fn();
    toast.success('Success');
    setLoading(false);
  }

  return (
    <Grid
      container
      flexDirection={'column'}
      border={1}
      borderColor={primaryColor}
      borderRadius={1}
      padding={1}
      width={'30%'}
      minWidth={'200px'}
    >
      <Grid item>
        <Button
          onClick={() => handleFunction()}
          variant={'outlined'}
          style={{ margin: '8px' }}
        >
          {loading ? <CircularProgress /> : <>{props.children}</>}
        </Button>
      </Grid>
      <Grid item>{props.description}</Grid>
    </Grid>
  );
};

const Selector = (props: { description: string }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>();
  const [event, setEvent] = useState<Event>();
  console.log('🚀 ~ file: ControlPanel.tsx:60 ~ Selector ~ event:', event);

  useEffect(() => {
    async function fetchData() {
      const data = await getEvents();
      if (data) {
        setEvents(data.data);
      }
      if (!event && data && data.data && data.data.length > 0) {
        if (data.data.find((e) => e.active === true)) {
          setEvent(data.data.find((e) => e.active === true));
        } else {
          setEvent(data.data[0]);
        }
      }
    }

    fetchData();
  }, []);

  const eventExists =
    events && event && events.findIndex((e) => e.name === event.name) !== -1;

  async function handleFunction() {
    setLoading(true);
    if (eventExists) {
      await putEvents(event.name);
      toast.success(`Set ${event.name} as active`);
    } else if (event) {
      await postEvents(event.name);
      toast.success(`Created event ${event.name}`);
    }
    setLoading(false);
  }

  function handleType(e: React.ChangeEvent<HTMLInputElement>) {
    if (!event) {
      return;
    }
    if (e.target.value === '') {
      const newEvents = events?.filter((ev) => ev.dateAdded !== new Date(0));
      setEvents(newEvents);
      if (newEvents) {
        setEvent(newEvents.find((ev) => ev.active === true));
      }
      return;
    }
    const newEvent: Event = {
      ...event,
      dateAdded: new Date(0),
      name: e.target.value,
    };
    setEvent(newEvent);
  }

  return (
    <Grid
      container
      flexDirection={'column'}
      border={1}
      borderColor={primaryColor}
      borderRadius={1}
      padding={1}
      width={'30%'}
      minWidth={'200px'}
    >
      <Grid
        container
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Grid item>
          <input
            type="text"
            name="new_event"
            placeholder="new event"
            onChange={handleType}
            maxLength={128}
            style={{
              padding: 5,
              margin: 5,
              backgroundColor: '#fbfbfb',
            }}
          />
        </Grid>
        {events ? (
          <Grid item>
            <select
              value={event?.name ?? ''}
              onChange={(e) =>
                setEvent(events?.find((ev) => ev.name === e.target.value))
              }
              style={{
                padding: 5,
                backgroundColor: '#fbfbfb',
                maxWidth: '5.5rem',
              }}
            >
              {events.map((e) => (
                <option key={e.name} value={e.name}>
                  {e.name}
                </option>
              ))}
              {event && !eventExists ? (
                <option value={event.name}>{event?.name}</option>
              ) : (
                <></>
              )}
            </select>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item>
          <Button
            onClick={() => handleFunction()}
            variant={'outlined'}
            style={{ margin: '8px' }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>{eventExists ? 'Set Event' : 'Add Event & Set Active'}</>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid item>{props.description}</Grid>
    </Grid>
  );
};

const ControlPanel = () => {
  const [settings, setSettings] = useRecoilState(settingsState);

  function handleClick(field: SettingsFieldNames) {
    const newState = { ...settings };
    if (field === 'allow_entry') {
      postSettings(field, !settings.allowEntry);
      newState.allowEntry = !settings.allowEntry;
    } else if (field === 'hide_unverified_when_locked') {
      postSettings(field, !settings.hideUnverifiedWhenLocked);
      newState.hideUnverifiedWhenLocked = !settings.hideUnverifiedWhenLocked;
    }
    setSettings(newState);
  }

  return (
    <div className="control-panel">
      <h3>Control Panel</h3>
      <Grid container flexDirection={'row'} gap={1} justifyContent={'center'}>
        <Action
          fn={() => handleClick('allow_entry')}
          description={'Lock after tournament started'}
        >
          {settings.allowEntry ? 'Lock Entries' : 'Allow Entries'}
        </Action>
        <Action
          fn={() => handleClick('hide_unverified_when_locked')}
          description="Hide unverified when entries are locked"
        >
          {settings.hideUnverifiedWhenLocked
            ? 'Show unverified'
            : 'Hide unverified'}
        </Action>
        <Action
          fn={() => postCalculateScore()}
          description={'calculate score for all brackets'}
        >
          calculate score
        </Action>
        <Action
          fn={() => postSimulateStart()}
          description={'Warning: Deletes all results'}
        >
          simulate start
        </Action>
        <Action
          fn={() => postSimulateFinish()}
          description={'Fills with random results'}
        >
          simulate finish
        </Action>
        <Selector description={'set current event (swap entries)'} />
      </Grid>
    </div>
  );
};

export default ControlPanel;
