import type { SetterOrUpdater } from 'recoil';

import config from '../config';
import type { Selection, Weight } from '../model/models';

export function clickedAthlete(
  weightclass: Weight,
  seed: number,
  selectedList: Selection,
  setSelectedList: SetterOrUpdater<Selection>,
  bout?: number,
  preventScrollToTop?: boolean
) {
  if (
    seed === 0 ||
    (bout && config.BRACKET_SIZE === 33 && bout > 180) ||
    (bout && config.BRACKET_SIZE === 16 && (bout < 180 || bout > 260))
  ) {
    return;
  }
  const newSelectedList = {
    ...selectedList,
    weights: { ...selectedList.weights, [weightclass]: seed },
  } as Selection;
  setSelectedList(newSelectedList);
  localStorage.setItem(
    `selection${weightclass.toString()}`,
    newSelectedList.weights[weightclass].toString()
  );

  // Reset wildcard if equal to selected
  if (weightclass === selectedList.wc.weight && seed === selectedList.wc.seed) {
    setSelectedList({
      ...newSelectedList,
      wc: { seed: 0, weight: 125 as Weight },
    });
    localStorage.removeItem('selectionWcSeed');
    localStorage.removeItem('selectionWcWeight');
  }

  // Scroll to top
  if (!preventScrollToTop) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
